import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Loader,
  PriceSummaryList,
  BasketModalContent,
  Price,
  Numpad
} from '@k3imagine/self-serve-components';
import * as S from './ManualCardPayment.styles';
import {
  PaymentDetails,
  SubmitPaymentProps,
  PaymentType
} from '../../../../../../services/payment/payment.type';
import { PaymentStatus } from '../../../../payment.types';
import GlobalContext from '../../../../../../state/GlobalContext';
import { NegativeSalesWarning } from '../../../../..';
import { DisableScreenClick } from '../../Payment.styles';

type ManualCardPaymentProps = {
  goNext: Function;
  goBack: Function;
};

const ManualCardPayment = ({ goNext, goBack }: ManualCardPaymentProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    currencyCode,
    negativeSaleMode,
    visualProfileColors,
    defaultLocale,
    addPayment,
    getPayments,
    getRemainder
  } = useContext(GlobalContext);
  const { t } = useTranslation();
  const styles = {
    bottomButtons: {
      button: visualProfileColors?.button?.primary
    }
  };

  const [amount, setAmount] = useState<string>(
    getRemainder(negativeSaleMode).toString()
  );

  const initiatePaymentProcess = async () => {
    setIsLoading(true);

    const paymentType: PaymentType = 'ManualCard';

    const submitPaymentProps: SubmitPaymentProps = {
      paymentType,
      totalPrice: Number(amount),
      currency: currencyCode
    };

    const payment = { paymentType, amount: submitPaymentProps.totalPrice };
    addPayment(payment);

    goNext({
      status: PaymentStatus.Success,
      paymentDescription: ''
    });
  };

  const priceSummary = [
    {
      id: 1,
      label: t('Basket.Total'),
      value: getRemainder(negativeSaleMode),
      bold: true
    }
  ];

  const payments = getPayments();
  let priceSummaryIndex = 2;
  payments?.forEach((payment: PaymentDetails) => {
    priceSummary.push({
      id: priceSummaryIndex,
      label: t(`Basket.${payment.paymentType}`),
      value: payment.amount,
      bold: false
    });
    priceSummaryIndex += 1;
  });

  const renderContent = () => {
    if (isLoading) {
      return (
        <S.TopMargin>
          <DisableScreenClick />
          <Loader width={200} color="black" />
        </S.TopMargin>
      );
    }

    if (negativeSaleMode) {
      return (
        <>
          <PriceSummaryList
            listItems={priceSummary}
            currencyCode={currencyCode}
          />
          <NegativeSalesWarning />
          <S.SubTitle>{t('Basket.RefundCardInfo')}</S.SubTitle>
        </>
      );
    }

    return (
      <>
        <PriceSummaryList
          listItems={priceSummary}
          currencyCode={currencyCode}
        />
        <S.NumpadWrapper>
          <S.SubTitle>{t('Basket.Amount')}:</S.SubTitle>
          <S.NumberBox>
            <Price
              currencyCode={currencyCode}
              locale={defaultLocale}
              value={Number(amount)}
            />
          </S.NumberBox>
          <Numpad
            onValueChanged={({ value }: { value: string }) => {
              setAmount(value);
            }}
          />
          <S.SubTitle>{t('Basket.CompleteTransactionThenContinue')}</S.SubTitle>
        </S.NumpadWrapper>
      </>
    );
  };

  return (
    <BasketModalContent
      styles={styles}
      title={t('Basket.ManualPayment')}
      buttonLabel={t('Basket.Confirm')}
      buttonIcon="BackArrow"
      buttonDisabled={
        Number(amount) > getRemainder() ||
        (Number(amount) <= 0 && !negativeSaleMode) ||
        isLoading
      }
      onIconButtonClicked={goBack}
      onButtonClicked={initiatePaymentProcess}
    >
      <S.Wrapper>{renderContent()}</S.Wrapper>
    </BasketModalContent>
  );
};

export default ManualCardPayment;
