import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ModifierSelection as ModifierSelectionComponent } from '@k3imagine/self-serve-components';
import {
  ModifierGroup,
  ModifierSelection as ModifierSelectionType,
  Modifier
} from '../../../types';

const ModifierSelection = ({
  modifierGroups,
  isNew = false,
  currencyCode,
  locale,
  selectedModifiers = [],
  onModifierSelectionChanged = () => {}
}: {
  modifierGroups: ModifierGroup[];
  isNew?: boolean;
  selectedModifiers: ModifierSelectionType[];
  onModifierSelectionChanged?: Function;
  locale?: string;
  currencyCode?: string;
}) => {
  let initialSelected: ModifierSelectionType[] = [];
  let availableModifierGroups: ModifierGroup[];
  const { t } = useTranslation();

  const generateMinMaxSubTitle = (
    min?: number,
    max?: number
  ): string | undefined => {
    if (min && max) {
      if (min === max) {
        return t('ModifierSelection.Choose', { min });
      }
      return t('ModifierSelection.ChooseMinToMax', { min, max });
    }

    if (min) {
      return t('ModifierSelection.ChooseAtLeast', { min });
    }

    if (max) {
      return t('ModifierSelection.ChooseUpTo', { max });
    }
  };

  if (isNew && selectedModifiers.length <= 0) {
    modifierGroups?.forEach(group => {
      group.modifiers.forEach(modifier => {
        if (modifier.defaultValue && modifier.defaultValue > 0) {
          initialSelected = [
            ...initialSelected,
            {
              referenceId: modifier.id,
              modifierId: modifier.id,
              modifierGroupId: group.id,
              label: modifier.name,
              defaultValue: modifier.defaultValue || 0,
              imageUrl: modifier.imageUrl,
              quantity: modifier.defaultValue || 0,
              salesPrice: modifier.salesPrice || 0
            }
          ];
        }
      });
    });

    availableModifierGroups = modifierGroups.map(group => ({
      ...group,
      subTitle: generateMinMaxSubTitle(group.min, group.max),
      modifiers: group.modifiers.map(m => ({
        ...m,
        displayName: m.name,
        quantity: m.defaultValue,
        subTitle: generateMinMaxSubTitle(m.min, m.max)
      }))
    }));
  } else {
    initialSelected = selectedModifiers;

    availableModifierGroups = modifierGroups.map(group => {
      return {
        ...group,
        subTitle: generateMinMaxSubTitle(group.min, group.max),
        modifiers: group.modifiers.map(m => {
          let selectedModifier;
          if (selectedModifiers.length > 0) {
            selectedModifier = selectedModifiers.find(
              sm => sm.referenceId === m.id
            );
          }
          return {
            ...m,
            displayName: m.name,
            quantity: selectedModifier?.quantity || 0,
            subTitle: generateMinMaxSubTitle(m.min, m.max)
          };
        })
      };
    });
  }

  const [modifierSelectionsState, setModifierSelectionsState] = useState<
    ModifierSelectionType[]
  >(initialSelected);

  // if more then default is selected and has salesPrice we add it
  const calculateModifierSelectionPrice = () => {
    let additionalPrice = 0;
    if (modifierSelectionsState && modifierSelectionsState.length > 0) {
      modifierSelectionsState.forEach(m => {
        const extraQuantity = (m.quantity || 0) - (m.defaultValue || 0);
        if (extraQuantity > 0) {
          additionalPrice += extraQuantity * m.salesPrice;
        }
      });
    }

    return additionalPrice;
  };

  useEffect(() => {
    onModifierSelectionChanged({
      updatedModifiers: modifierSelectionsState,
      modifierAdditionalPrice: calculateModifierSelectionPrice()
    });
  }, [modifierSelectionsState]);

  const handleModifierSelectionChange = ({
    group,
    selectedOptions
  }: {
    group: ModifierGroup;
    selectedOptions: Modifier[];
  }) => {
    let updatedSelection: ModifierSelectionType[] = [];
    if (modifierSelectionsState && modifierSelectionsState.length > 0) {
      updatedSelection = modifierSelectionsState.filter(
        m => m.modifierGroupId !== group.id
      );
    }

    updatedSelection = [
      ...updatedSelection,
      ...selectedOptions.map(modifier => ({
        referenceId: modifier.id,
        modifierId: modifier.id,
        modifierGroupId: group.id,
        label: modifier.name,
        defaultValue: modifier.defaultValue || 0,
        imageUrl: modifier.imageUrl,
        quantity: modifier.quantity || 0,
        salesPrice: modifier.salesPrice || 0
      }))
    ];
    setModifierSelectionsState(updatedSelection);
  };

  return (
    <ModifierSelectionComponent
      locale={locale}
      currencyCode={currencyCode}
      modifierGroups={availableModifierGroups}
      onChanged={handleModifierSelectionChange}
    />
  );
};

export default ModifierSelection;
