import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@k3imagine/self-serve-components';

import GlobalContext from '../../../../../state/GlobalContext';
import * as S from './BottomButtons.styles';

type PaymentButtonsProps = {
  goNext: Function;
  emptyBasket: Function;
};

const BottomButtons = ({ goNext, emptyBasket }: PaymentButtonsProps) => {
  const { t } = useTranslation();
  const {
    selectedPaymentDevice,
    visualProfileColors,
    basketLoading
  } = useContext(GlobalContext);

  const textColor = visualProfileColors?.button?.primary?.text || 'white';
  const backgroundColor =
    visualProfileColors?.button?.primary?.background || 'green';

  return (
    <S.Wrapper>
      <S.TrashButton
        onClick={() => emptyBasket()}
        backgroundColor={backgroundColor}
        textColor={textColor}
      >
        <S.TrashIconWrapper>
          <Icon type="Trash" color="white" />
        </S.TrashIconWrapper>
      </S.TrashButton>
      <S.PaymentButton
        disabled={!selectedPaymentDevice || basketLoading}
        onClick={() => (basketLoading ? null : goNext('Card'))}
        backgroundColor={backgroundColor}
        textColor={textColor}
      >
        <S.IconWrapper>
          <Icon type="Card" color={textColor} />
        </S.IconWrapper>
        {t('Basket.Card')}
      </S.PaymentButton>
      <S.PaymentButton
        disabled={!selectedPaymentDevice || basketLoading}
        onClick={() => (basketLoading ? null : goNext('Cash'))}
        backgroundColor={backgroundColor}
        textColor={textColor}
      >
        <S.IconWrapper>
          <Icon type="Cash" color={textColor} />
        </S.IconWrapper>
        {t('Basket.Cash')}
      </S.PaymentButton>
      <S.PaymentButton
        disabled={basketLoading}
        onClick={() => (basketLoading ? null : goNext('ManualCard'))}
        backgroundColor={backgroundColor}
        textColor={textColor}
      >
        <S.IconWrapper>
          <Icon type="Card" color={textColor} />
        </S.IconWrapper>
        {t('Basket.ManualCard')}
      </S.PaymentButton>
    </S.Wrapper>
  );
};

export default BottomButtons;
