import { useState } from 'react';
import { Clerk, ClerkStateType } from '../types';

const useClerkState = (): ClerkStateType => {
  const [clerk, setClerk] = useState<Clerk>();

  return {
    clerk,
    setClerk
  };
};

export default useClerkState;
