import React, { useState, useContext, ComponentProps } from 'react';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import {
  notify,
  Numpad,
  LanguagePicker,
  FlagIcon,
  MultiButton
} from '@k3imagine/self-serve-components';
import { getClerkByClerkNumber } from '../../services/clerk.service';
import { Clerk } from '../../types';
import GlobalContext from '../../state/GlobalContext';
import * as S from './StartScreen.styles';
import i18n from '../../locales/i18n';
import { getClerkPasswordSha256 } from '../../utils/encryption';

export type FlagIconProps = ComponentProps<typeof FlagIcon>['code'];
type LoginSteps = 'clerk_number' | 'clerk_password';

export const DEFAULT_IMAGE =
  'https://k3imagine.blob.core.windows.net/selfserv/K3imagine-logo-dark.png';

const StartScreen = () => {
  const {
    setClerk,
    currentCountry,
    supportedLanguages,
    setCurrentCountry,
    visualProfileColors,
    rootComposerGroup,
    startScreenImage
  } = useContext(GlobalContext);

  const [loginStep, setLoginStep] = useState<LoginSteps>('clerk_number');
  const [clerkNumberInput, setClerkNumberInput] = useState<string>();
  const [clerkPasswordInput, setClerkPasswordInput] = useState<string>();
  const [hashedClerkPasswordInput, setHashedClerkPasswordInput] = useState<
    string
  >();
  const [fetchingClerk, setFetchingClerk] = useState<boolean>(false);
  const [fetchedClerk, setFetchedClerk] = useState<Clerk>();

  const { t } = useTranslation();

  const availableLanguages: {
    code: FlagIconProps;
  }[] = supportedLanguages.map((language: string) => ({
    code: language.split('-')[1] as FlagIconProps
  }));

  const changeLanguage = (country: string) => {
    i18n.changeLanguage(country);
    setCurrentCountry(country);
  };

  const stringToPassword = (value?: string) => {
    if (!value) {
      return '';
    }
    const valueArray = Array.from(String(value), Number);
    return valueArray.map(() => 'x');
  };

  const fetchClerk = async () => {
    setFetchingClerk(true);
    const currentClerkNumber = clerkNumberInput || '';
    try {
      const clerkFound = await getClerkByClerkNumber(currentClerkNumber);

      if (clerkFound) {
        setFetchedClerk(clerkFound);
        setLoginStep('clerk_password');
      }
    } catch {
      notify({
        message: t('StartScreen.ClerkNumberDoesNotExist', {
          clerkNumber: currentClerkNumber
        }),
        type: 'error'
      });
    }
    setFetchingClerk(false);
  };

  const goNext = async () => {
    switch (loginStep) {
      case 'clerk_number':
        fetchClerk();
        break;
      case 'clerk_password':
        setClerk(fetchedClerk);
        navigate(`/groups/${rootComposerGroup?.groupUrlName}`);
        break;
      default:
        break;
    }
  };

  const isCorrectPassword = () => {
    return hashedClerkPasswordInput === fetchedClerk?.password;
  };

  const renderContent = () => {
    switch (loginStep) {
      case 'clerk_number':
        return (
          <>
            <S.Title key="clerk_number">
              {t('StartScreen.EnterClerkNumber')}
            </S.Title>
            <S.NumpadWrapper>
              <S.Input>{clerkNumberInput}</S.Input>
              <Numpad
                key="clerk_number"
                initialValue={clerkNumberInput}
                onValueChanged={({ value }: { value: string }) =>
                  setClerkNumberInput(value)
                }
              />
            </S.NumpadWrapper>

            <S.ButtonWrapper>
              <MultiButton
                buttonLabel={t('Next')}
                onButtonClicked={goNext}
                buttonDisabled={!clerkNumberInput}
                buttonLoading={fetchingClerk}
                styles={{ button: visualProfileColors?.button?.primary }}
              />
            </S.ButtonWrapper>
          </>
        );
      case 'clerk_password':
        return (
          <>
            <S.Title key="clerk_password">
              {t('StartScreen.EnterClerkPassword')}
            </S.Title>
            <S.NumpadWrapper>
              <S.Input>{stringToPassword(clerkPasswordInput)}</S.Input>
              <Numpad
                key="clerk_password"
                initialValue={clerkPasswordInput}
                onValueChanged={async ({ value }: { value: string }) => {
                  setClerkPasswordInput(value);
                  const hashedInput = await getClerkPasswordSha256(
                    fetchedClerk?.id,
                    value
                  );
                  setHashedClerkPasswordInput(hashedInput);
                }}
              />
            </S.NumpadWrapper>
            <S.ButtonWrapper>
              <MultiButton
                buttonLabel={t('Done')}
                onButtonClicked={goNext}
                buttonDisabled={!isCorrectPassword()}
                leftButtonIcon="BackArrow"
                onLeftButtonClicked={() => {
                  setLoginStep('clerk_number');
                  setClerkPasswordInput('');
                }}
                styles={{ button: visualProfileColors?.button?.primary }}
              />
            </S.ButtonWrapper>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <S.Container>
      <S.Image
        imageUrl={startScreenImage || DEFAULT_IMAGE}
        cover={(startScreenImage || DEFAULT_IMAGE) !== DEFAULT_IMAGE}
      />
      <S.Content>
        <S.LanguagePickerWrapper>
          <LanguagePicker
            languageOptions={availableLanguages}
            currentLanguage={{
              // Note: The language picker is based on country, not language
              code: currentCountry as FlagIconProps
            }}
            onLanguageChanged={({ code }: { code: FlagIconProps }) => {
              // Note: The language picker is based on country, not language
              changeLanguage(code);
            }}
          />
        </S.LanguagePickerWrapper>
        {renderContent()}
      </S.Content>
    </S.Container>
  );
};

export default StartScreen;
