export enum PaymentStatus {
  Success = 'Success',
  Failure = 'Failure'
}

export type SaleItem = {
  itemId: number;
  quantity: number;
  orderDetail?: string;
};
