import axios from 'axios';

import {
  CompletePaymentProps,
  IPaymentService,
  PaymentDevice,
  PaymentResponse,
  PaymentServiceError,
  SubmitPaymentProps,
  PrintReceiptProps
} from '../../payment.type';

import {
  ArmPaymentRequest,
  TerminalStatusResponse,
  AddPaymentResponse,
  ArmPrintReceipt,
  TerminalStatusResult
} from './arm-payment.types';

import { printTranslations } from './arm-payment.constants';
import { SalesReceipt } from '../../../sales/sales.type';

const api = axios.create({
  baseURL: `${process.env.API_URL}`,
  headers: {
    'Content-Type': 'application/json'
  }
});

const printTerminalReceipt = (bonText: string, url: string) => {
  // eslint-disable-next-line
  console.log('ARM - Print Card Receipt');

  api.post(`${url}/PrintCardReceipt`, { receipt: bonText });
};

// we will try to fetch the terminal status max 50 times. When we get a terminal status >= 0 we stop
const getTerminalStatus = async (
  armDeviceUrl: string,
  sessionId: string,
  retries: number = 50
): Promise<TerminalStatusResponse> => {
  const armResponse = await api.post(`${armDeviceUrl}/GetTerminalStatus`, {
    sessionId
  });
  const terminalStatus = armResponse?.data?.d?.Result?.Result as number;

  // if bon text we print card receipt straight away
  if (armResponse?.data?.d?.Result?.BonText) {
    printTerminalReceipt(armResponse?.data?.d?.Result?.BonText, armDeviceUrl);
  }

  // negative numbers mean that we want to try to fetch again
  // positive numbers mean we got a terminal status response
  // terminal status 0 = Success, other positive mean error
  if (terminalStatus >= 0) {
    const responseData = armResponse?.data?.d;

    const cardPan = responseData.Result?.CardPan;
    const cardName = responseData.Result?.CardName;
    const authorizationCode = responseData.Result?.TokenId;
    const response: TerminalStatusResponse = {
      cardPan,
      cardName,
      authorizationCode,
      terminalStatus
    };
    return response;
  }

  if (retries > 0) {
    return getTerminalStatus(armDeviceUrl, sessionId, retries - 1);
  }

  const error: PaymentServiceError = {
    message: 'GetTerminalStatus failed.'
  };
  throw error;
};

const addPayment = async (
  armDeviceUrl: string,
  paymentRequest: ArmPaymentRequest
): Promise<AddPaymentResponse> => {
  const armResponse = await api.post(`${armDeviceUrl}/AddPayment`, {
    paymentRequest
  });

  const addPaymentResponse: AddPaymentResponse = {
    sessionId: armResponse?.data?.d?.Result?.Message
  };
  return addPaymentResponse;
};

const getArmDeviceUrl = (url: string) => {
  return `https://${url}/POSServiceHandler.aspx`;
};

export const ArmPaymentService = (
  paymentDevice: PaymentDevice
): IPaymentService => {
  const submitPayment = async (
    props: SubmitPaymentProps
  ): Promise<PaymentResponse> => {
    // eslint-disable-next-line
    console.log('ARM - Submit Payment', props);

    if (!paymentDevice.url) {
      const error: PaymentServiceError = {
        message: 'No URL set on ARM device.'
      };
      throw error;
    }

    const armDeviceUrl = getArmDeviceUrl(paymentDevice.url);

    const { totalPrice, currency, paymentType } = props;
    const paymentRequest: ArmPaymentRequest = {
      amount: totalPrice ? totalPrice.toString() : '0',
      paymentType,
      exchangerate: 100,
      currencyName: currency,
      rounding: 0,
      foreignCurrencyCode: null,
      receipt: {},
      printTranslations,
      group: '1'
    };

    const addPaymentResponse: AddPaymentResponse = await addPayment(
      armDeviceUrl,
      paymentRequest
    );

    let paymentResponse: PaymentResponse;
    // TODO: Build exception handling for throws.
    // TODO: Look at the object, check the status codes before proceeding

    if (paymentType === 'Card') {
      const { sessionId } = addPaymentResponse;

      const terminalStatusResponse: TerminalStatusResponse = await getTerminalStatus(
        armDeviceUrl,
        sessionId
      );

      paymentResponse = {
        isSuccessful:
          terminalStatusResponse.terminalStatus ===
          TerminalStatusResult.Success,
        cardName: terminalStatusResponse.cardName
      };
    } else {
      paymentResponse = {
        isSuccessful: true
      };
    }

    return paymentResponse;
  };

  
  const createArmReceiptObject = (
    receipt: SalesReceipt,
    orderId: number,
    isCopy: boolean = false
  ) => {
    let orderNumber = '          ';
    if (orderId) orderNumber = `Order: ${orderId}`;

    return {
      receipt: {
        ...receipt,
        orderNumber,
        printReceipt: false
      },
      printTranslations,
      cleanCash: '',
      isCopy
    };
  };

  const completePayment = async (props: CompletePaymentProps) => {
    // eslint-disable-next-line
    console.log('ARM - Complete Payment', props);
    if (!paymentDevice.url) {
      const error: PaymentServiceError = {
        message: 'No URL set on ARM device.'
      };
      throw error;
    }
    const armDeviceUrl = getArmDeviceUrl(paymentDevice.url);
    checkPaperStatus(armDeviceUrl);
    const { receipt, orderId } = props.salesResponse;
    const receiptData: ArmPrintReceipt = createArmReceiptObject(
      receipt,
      orderId
    );

    await api.post(`${armDeviceUrl}/CompleteReceipt`, receiptData);
  };

  const printLastReceipt = async (props: PrintReceiptProps) => {
    // eslint-disable-next-line
    console.log('ARM - Print Last Receipt');
    if (!props.salesResponse) {
      // eslint-disable-next-line
      console.error('No receipt in store.');
      return;
    }
    

    if (!paymentDevice.url) {
      const error: PaymentServiceError = {
        message: 'No URL set on ARM device.'
      };
      throw error;
    }
    const armDeviceUrl = getArmDeviceUrl(paymentDevice.url);
    checkPaperStatus(armDeviceUrl);
    const { receipt, orderId } = props.salesResponse;
    const lastReceipt: ArmPrintReceipt = createArmReceiptObject(
      receipt,
      orderId,
      props.isCopy
    );

    // as the arm device gives us an error if we include the id in the request
    if (lastReceipt.receipt.id !== undefined) {
      delete lastReceipt.receipt.id;
    }

    await api.post(`${armDeviceUrl}/PrintReceiptCopy`, lastReceipt);
  };

  const checkPaperStatus = async(url: string) => {
    try {
      return (await api.post(`${url}/PaperStatus`)).data;
    } 
    catch (error) {
      return 1;
    }
  }
  

  const wakeUp = async () => {
    if (!paymentDevice.url) {
      const error: PaymentServiceError = {
        message: 'No URL set on ARM device.'
      };
      throw error;
    }
    try {
      const armDeviceUrl = getArmDeviceUrl(paymentDevice.url);
      const response = await api.post(`${armDeviceUrl}/WakeUp`,{});
      const result = new Promise(resolve =>
        setTimeout(() => resolve(response.data), 1000)
      );
      return result;
    } catch (e) {
      const error: PaymentServiceError = {
        message: 'Could not connect to Connector'
      };
      throw error;
    }
  };

  return {
    submitPayment,
    completePayment,
    printLastReceipt,
    wakeUp
  };
};
