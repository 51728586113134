export const getClerkPasswordSha256 = async function sha256(
  clerkId: number = 0,
  clerkPassword: string = ''
) {
  // encode as UTF-16
  const message = `${clerkId}.${clerkPassword}`;
  const buf = new ArrayBuffer(message.length * 2);
  const msgBuffer = new Uint16Array(buf);
  for (let i = 0, strLen = message.length; i < strLen; i += 1) {
    msgBuffer[i] = message.charCodeAt(i);
  }

  // hash the message
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // convert bytes to hex string
  const hashHex = hashArray.map(b => `00${b.toString(16)}`.slice(-2)).join('');
  return hashHex.toUpperCase();
};
