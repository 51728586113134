import styled from 'styled-components';

export const DiscountButton = styled.button`
  padding: 7px;
  background: white;

  cursor: pointer;
  font-size: 16px;
  border: 1px solid #000000;
  border-radius: 26px;
`;

export const DiscountButtonsWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 5fr 2fr 2fr;

  > button {
    margin: 8px;
  }
`;

export const IconWrapper = styled.span`
  margin-left: 5px;
  padding: auto;
  vertical-align: sub;
`;
