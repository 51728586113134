import { createGlobalStyle } from 'styled-components';
import { Font } from '../types';

export const GlobalStyles = createGlobalStyle<{ font?: Font }>`
  @import url(${props => props.font?.stylesheetUrl});

  body {
    margin: 0px;
  };

  * {
    font-family: ${props =>
      props.font?.name || 'Source Sans Pro'}, 'Source Sans Pro';
    font-style: normal;
    font-weight: normal;

 
    touch-action: manipulation;
    
  }
`;
