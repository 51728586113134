import React, { useContext } from 'react';
import {
  Select,
  notify,
  PaymentButton
} from '@k3imagine/self-serve-components';
import { useTranslation } from 'react-i18next';

import GlobalContext from '../../../state/GlobalContext';
import {
  PaymentProviderEnum,
  PaymentDevice
} from '../../../services/payment/payment.type';
import { ImagineAndroidAppInterface } from '../../../services/payment/payments/android/types/appInterface.types';
import AppInterface from '../../../services/payment/payments/android/app-interface/AppInterface';
import {
  FullScreenButtonWrapper,
  InfoText,
  SubTitle
} from '../SupervisorModal.styles';

type PaymentProviderOption = {
  label: string;
  value: string;
};

const PaymentProvider = () => {
  const {
    selectedPaymentDevice,
    setSelectedPaymentDevice,
    availablePaymentDevices
  } = useContext(GlobalContext);
  const { t } = useTranslation();
  const App: ImagineAndroidAppInterface = AppInterface;
  const isAndroidPaymentDevice: boolean =
    selectedPaymentDevice !== undefined &&
    selectedPaymentDevice.type === PaymentProviderEnum.AndroidAppPayment;

  if (isAndroidPaymentDevice) {
    return (
      <div>
        <InfoText>{selectedPaymentDevice?.name}</InfoText>
        <SubTitle>{t('Supervisor.AndroidAppFunctions')}:</SubTitle>
        <FullScreenButtonWrapper>
          <PaymentButton
            label={t('Supervisor.AndroidEndOfDay')}
            onClicked={() => App.PostEndOfDay()}
          />
        </FullScreenButtonWrapper>
        <FullScreenButtonWrapper>
          <PaymentButton
            label={t('Supervisor.AndroidClearCache')}
            onClicked={() => App.ClearAppCache()}
          />
        </FullScreenButtonWrapper>
      </div>
    );
  }

  const options = availablePaymentDevices.map(device => ({
    label: device.name,
    value: device.uniqueId
  }));
  const selected = options.find(
    device => device.value === selectedPaymentDevice?.uniqueId
  );

  const handleChange = (option: PaymentProviderOption) => {
    const uniqueId = option.value;
    const newDevice = availablePaymentDevices.find(
      (device: PaymentDevice) => device.uniqueId === uniqueId
    );

    if (!isAndroidPaymentDevice && !newDevice?.url) {
      notify({
        message: t('Supervisor.PaymentDeviceNotReadyError'),
        type: 'error'
      });
      return;
    }

    setSelectedPaymentDevice(newDevice);
  };

  return (
    <Select
      value={selected}
      options={options}
      onChange={handleChange}
      searchable
    />
  );
};

export default PaymentProvider;
