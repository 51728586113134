import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Loader,
  PriceSummaryList,
  Numpad,
  Price,
  BasketModalContent
} from '@k3imagine/self-serve-components';
import * as S from './CashPayment.styles';
import { getPaymentService } from '../../../../../../services/payment/payment.service';
import {
  IPaymentService,
  PaymentResponse,
  PaymentDetails,
  SubmitPaymentProps
} from '../../../../../../services/payment/payment.type';
import GlobalContext from '../../../../../../state/GlobalContext';
import { NegativeSalesWarning } from '../../../../..';
import { BasketType, PaymentStatus } from '../../../../../../types';
import { DisableScreenClick } from '../../Payment.styles';

type CashPaymentProps = {
  goNext: Function;
  goBack: Function;
};

const CashPayment = ({ goNext, goBack }: CashPaymentProps) => {
  const [cash, setCash] = useState<string>('0');
  const [change, setChange] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    selectedPaymentDevice,
    basket,
    currencyCode,
    defaultLocale,
    negativeSaleMode,
    getNegativeSalesBasket,
    visualProfileColors,
    addPayment,
    getPayments,
    getRemainder
  } = useContext(GlobalContext);
  const { t } = useTranslation();
  const styles = {
    bottomButtons: {
      button: visualProfileColors?.button?.primary
    }
  };
  const finalBasket: BasketType = negativeSaleMode
    ? getNegativeSalesBasket()
    : basket;

  let payments: PaymentDetails[] = [
    { paymentType: 'Cash', amount: Number(cash) },
    { paymentType: 'Change', amount: -change }
  ];

  const initiatePaymentProcess = async () => {
    setIsLoading(true);

    if (!selectedPaymentDevice) {
      goNext({
        status: PaymentStatus.Failure,
        paymentDescription: t('Error.NoPaymentProviderSelected')
      });
      return;
    }

    const PaymentService: IPaymentService = getPaymentService(
      selectedPaymentDevice
    );
    let paymentResponse: PaymentResponse;

    if (negativeSaleMode) {
      payments = [{ paymentType: 'Cash', amount: finalBasket.totalPrice }];
    }

    try {
      const paymentResponseSuccess: boolean[] = [];
      payments?.forEach(async (payment: PaymentDetails) => {
        const submitPaymentProps: SubmitPaymentProps = {
          paymentType: payment.paymentType,
          totalPrice: payment.amount,
          currency: currencyCode
        };

        paymentResponse = await PaymentService.submitPayment(
          submitPaymentProps
        );

        paymentResponseSuccess.push(paymentResponse.isSuccessful);
      });

      if (paymentResponseSuccess.every(success => success === true)) {
        if (!negativeSaleMode) {
          addPayment({ paymentType: 'Cash', amount: Number(cash) });
          if (change > 0) {
            addPayment({ paymentType: 'Change', amount: Number(-change) });
          }
        } else {
          addPayment({ paymentType: 'Cash', amount: finalBasket.totalPrice });
        }

        goNext({
          status: PaymentStatus.Success,
          paymentDescription: ''
        });
      } else {
        goNext({
          status: PaymentStatus.Failure,
          paymentDescription: t('Basket.FailedToSubmitPayment')
        });
        return;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn('exception:', e);

      goNext({
        status: PaymentStatus.Failure,
        paymentDescription: t('Basket.FailedToSubmitPayment')
      });
    }
  };

  const calculateChange = (value: String) => {
    const unroundedChange = Number(value) - getRemainder();
    const roundedChange =
      Math.round((unroundedChange + Number.EPSILON) * 100) / 100;
    setChange(roundedChange);
  };

  const totalPriceSummary = [
    {
      id: 1,
      label: t('Basket.Total'),
      value: getRemainder(negativeSaleMode),
      bold: true
    }
  ];

  const previousPayments = getPayments();
  let priceSummaryIndex = 2;
  previousPayments?.forEach((previousPayment: PaymentDetails) => {
    totalPriceSummary.push({
      id: priceSummaryIndex,
      label: t(`Basket.${previousPayment.paymentType}`),
      value: previousPayment.amount,
      bold: false
    });
    priceSummaryIndex += 1;
  });

  const priceSummary = [
    ...totalPriceSummary,
    {
      id: priceSummaryIndex,
      label: t('Basket.Cash'),
      value: Number(cash)
    },
    {
      id: priceSummaryIndex + 1,
      label: t('Basket.Change'),
      value: change < 0 ? 0 : -change
    }
  ];

  const renderContent = () => {
    if (isLoading) {
      return (
        <S.TopMargin>
          <DisableScreenClick />
          <Loader width={200} color="black" />
        </S.TopMargin>
      );
    }

    if (negativeSaleMode) {
      return (
        <>
          <PriceSummaryList
            listItems={totalPriceSummary}
            currencyCode={currencyCode}
          />
          <NegativeSalesWarning />
          <S.SubTitle>{t('Basket.RefundCashInfo')}</S.SubTitle>
        </>
      );
    }

    return (
      <>
        <PriceSummaryList
          listItems={priceSummary}
          currencyCode={currencyCode}
        />
        <NegativeSalesWarning />
        <S.NumpadWrapper>
          <S.SubTitle>{t('Basket.EnterCash')}:</S.SubTitle>
          <S.NumberBox>
            <Price
              currencyCode={currencyCode}
              locale={defaultLocale}
              value={Number(cash)}
            />
          </S.NumberBox>
          <Numpad
            onValueChanged={({ value }: { value: string }) => {
              setCash(value);
              calculateChange(value);
            }}
          />
        </S.NumpadWrapper>
      </>
    );
  };

  return (
    <BasketModalContent
      styles={styles}
      title={t('Basket.CashPayment')}
      buttonLabel={t('Basket.Confirm')}
      buttonIcon="BackArrow"
      buttonDisabled={Number(cash) === 0 && !negativeSaleMode}
      onIconButtonClicked={goBack}
      onButtonClicked={initiatePaymentProcess}
    >
      <S.Wrapper>{renderContent()}</S.Wrapper>
    </BasketModalContent>
  );
};

export default CashPayment;
