import React, { useContext } from 'react';
import { notify } from '@k3imagine/self-serve-components';
import { useTranslation } from 'react-i18next';

// @ts-ignore
import BarcodeReader from 'react-barcode-reader';
import { searchByBarcode } from '../../services/search.service';
import GlobalContext from '../../state/GlobalContext';
import { LayoutContext } from '../Layout/Layout';
import { BasketItem } from '../../types';

const BarcodeReaderComponent = () => {
  const { addBasketItem } = useContext(GlobalContext);
  const {
    setShowProductDetailModal,
    setProductDetailModalInfo,
    setShowBasketModal
  } = useContext(LayoutContext);

  const { t } = useTranslation();

  const customizeScannedProduct = (basketItem: BasketItem) => {
    setProductDetailModalInfo({
      product: basketItem,
      isNew: true
    });
    setShowProductDetailModal(true);
  };

  const addScannedProductToBasket = (basketItem: BasketItem) => {
    addBasketItem(basketItem);
    setShowBasketModal(true);
  };

  const handleBarcodeScanned = async (barcode: string) => {
    const { isBundle, item, bundle } = await searchByBarcode(barcode);

    if (isBundle && bundle) {
      const basketItem: BasketItem = {
        referenceId: bundle.id,
        imageUrl: bundle.imageUrl,
        label: bundle.displayName,
        price: bundle?.salesPrice,
        quantity: 1,
        type: 'Bundle',
        salesPrice: item.salesPrice
      };
      customizeScannedProduct(basketItem);
    } else if (item) {
      const basketItem: BasketItem = {
        referenceId: item.id,
        imageUrl: item.imageUrl,
        price: item?.salesPrice,
        label: item.displayName,
        quantity: 1,
        type: 'Item',
        salesPrice: item.salesPrice
      };
      if (item.modifierGroups && item.modifierGroups?.length > 0) {
        customizeScannedProduct(basketItem);
      } else {
        addScannedProductToBasket(basketItem);
      }
    } else {
      notify({
        message: t('Error.ItemScannedNotFound'),
        type: 'error'
      });
    }
  };

  // here we expose our barcode scanning to returnScannedBarcode to be able to listen to the Android device
  (window as typeof globalThis & {
    returnScannedBarcode?: Function;
  }).returnScannedBarcode = handleBarcodeScanned;

  return (
    <BarcodeReader
      onScan={(data: string) => handleBarcodeScanned(data)}
      avgTimeByChar={100} // Average time (ms) between 2 chars. Used to do difference between keyboard typing and scanning
      minLength={2} // Minimum length for a scanning
    />
  );
};

export default BarcodeReaderComponent;
