import { ArmPaymentService } from './payments/arm/arm-payment.service';
import { AndroidPaymentService } from './payments/android/android-payment.service';
import { VirtualPaymentService } from './payments/virtual/virtual-payment.service';

import {
  IPaymentService,
  PaymentDevice,
  PaymentProviderEnum,
  PaymentServiceError
} from './payment.type';

export const PaymentServiceMap = {
  [PaymentProviderEnum.Arm]: ArmPaymentService,
  [PaymentProviderEnum.AndroidAppPayment]: AndroidPaymentService,
  [PaymentProviderEnum.Virtual]: VirtualPaymentService
};

export const getPaymentService = (
  paymentDevice: PaymentDevice
): IPaymentService => {
  const { type } = paymentDevice;
  if (!paymentDevice) {
    const error: PaymentServiceError = {
      message: 'Payment device is not set.'
    };
    throw error;
  }

  const paymentService = PaymentServiceMap[type];
  return paymentService(paymentDevice);
};
