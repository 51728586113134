import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ProductDetailModal as ProductDetailModalComponent,
  Loader,
  Tabs
} from '@k3imagine/self-serve-components';
import * as S from './ProductDetailModal.styles';
import {
  OrderCustomization,
  BundleSelection,
  ModifierSelection,
  Item,
  ModifierGroup
} from '../../types';

import GlobalContext from '../../state/GlobalContext';
import { getItemById } from '../../services/item.service';
import { getBundleById } from '../../services/bundle.service';
import { LayoutContext } from '..';
import { ProductType } from './ProductDetailModal.types';
import BundleSelectionComponent from './BundleSelection/BundleSelection';
import ModifierSelectionComponent from './ModifierSelection/ModifierSelection';

const ProductDetailModal = () => {
  const [quantity, setQuantity] = useState<number>(1);
  const [
    additionalPriceBreakdownState,
    setAdditionalPriceBreakdownState
  ] = useState<{
    modifierSelectionPrice?: number;
    bundleSelectionPrice?: number;
  }>({ modifierSelectionPrice: 0, bundleSelectionPrice: 0 });
  const [detailedProductInfo, setDetailedProductInfo] = useState<ProductType>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [newOrderCustomization, setNewOrderCustomization] = useState<
    OrderCustomization
  >({});
  const [currentTab, setCurrentTab] = useState<{ id: number; label?: string }>({
    id: 1
  });
  const [tabs, setTabs] = useState<{ id: number; label?: string }[]>([]);
  const {
    defaultLocale,
    currencyCode,
    updateBasketItem,
    addBasketItem,
    visualProfileColors
  } = useContext(GlobalContext);
  const {
    showProductDetailModal,
    setShowProductDetailModal,
    productDetailModalInfo
  } = useContext(LayoutContext);
  const { t } = useTranslation();

  const bundleSelectionTitle = t('BundleSelection');
  const mainItemSelectionTitle = t('MainItemModification');
  const modifierTitle = t('Modifiers');
  const itemModifierSelectionTab = { id: 2, label: modifierTitle };
  const bundleGroupSelectionTab = { id: 3, label: bundleSelectionTitle };
  const bundleModifierSelectionTab = { id: 4, label: mainItemSelectionTitle };

  const styles = {
    bottomButtons: {
      button: visualProfileColors?.button?.primary
    }
  };

  const getTabs = (item: Item) => {
    if (productDetailModalInfo?.product.type === 'Bundle') {
      // Bundle with Bundle group selection and modifiers on main item
      if (item?.modifierGroups && item?.modifierGroups.length > 0) {
        setCurrentTab(bundleGroupSelectionTab);

        return [bundleGroupSelectionTab, bundleModifierSelectionTab];
      }
      // Bundle with just Bundle Group Selection
      setCurrentTab(bundleGroupSelectionTab);
      return [bundleGroupSelectionTab];
    }

    // Item with Modifiers
    if (
      productDetailModalInfo?.product.type === 'Item' &&
      item.modifierGroups &&
      item.modifierGroups.length > 0
    ) {
      setCurrentTab(itemModifierSelectionTab);
      return [itemModifierSelectionTab];
    }

    setCurrentTab({ id: 1 });
    return [];
  };

  useEffect(() => {
    setDetailedProductInfo(undefined);

    const getProduct = async () => {
      setIsLoading(true);

      if (productDetailModalInfo && productDetailModalInfo.product) {
        const { product, isNew } = productDetailModalInfo;
        if (!isNew) {
          const { customization } = product;
          setQuantity(product.quantity || 1);

          if (customization && customization?.additionalPriceBreakdown) {
            setAdditionalPriceBreakdownState(
              customization.additionalPriceBreakdown
            );
          }

          setNewOrderCustomization(customization || {});
        } else {
          setQuantity(1);
          setNewOrderCustomization({});
        }

        if (product.type === 'Item' && product.referenceId) {
          const item = await getItemById(product.referenceId);
          setTabs(getTabs(item));
          setDetailedProductInfo(item);
        }
        if (product.type === 'Bundle' && product.referenceId) {
          const bundle = await getBundleById(product.referenceId);
          setTabs(getTabs(bundle.item));
          setDetailedProductInfo(bundle);
        }
      }

      setIsLoading(false);
    };
    getProduct();
  }, [productDetailModalInfo]);

  const handleQuantityChange = (actionType: '+' | '-') => {
    if (actionType === '+') {
      setQuantity(quantity + 1);
    }
    if (actionType === '-' && quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handleModalClose = () => {
    setShowProductDetailModal(false);
    setAdditionalPriceBreakdownState({
      modifierSelectionPrice: 0,
      bundleSelectionPrice: 0
    });
  };

  const goToNextTab = () => {
    const tabIndex = tabs.findIndex(x => x === currentTab);
    const nextTab = tabs[tabIndex + 1];

    if (nextTab) {
      setCurrentTab(nextTab);
    }
  };

  const onBundleGroupItemSelected = (
    newBundleSelections: BundleSelection[]
  ) => {
    setNewOrderCustomization({
      ...newOrderCustomization,
      bundleSelections: newBundleSelections
    });

    // after selecting from the last bundle group we go to next tab
    const bundleGroupLength = detailedProductInfo?.bundleGroups?.length || 0;
    if (newBundleSelections.length === bundleGroupLength) {
      goToNextTab();
    }
  };

  const renderBundleSelection = () => {
    return (
      <BundleSelectionComponent
        bundleGroups={detailedProductInfo?.bundleGroups}
        selectedBundleGroups={newOrderCustomization.bundleSelections}
        onBundleSelectionChanged={onBundleGroupItemSelected}
      />
    );
  };

  const renderModifierSelection = (
    modifierGroups: ModifierGroup[],
    isNew?: boolean
  ) => {
    return (
      <ModifierSelectionComponent
        modifierGroups={modifierGroups}
        currencyCode={currencyCode}
        locale={defaultLocale}
        selectedModifiers={newOrderCustomization.modifierSelections || []}
        isNew={isNew}
        onModifierSelectionChanged={({
          updatedModifiers,
          modifierAdditionalPrice
        }: {
          updatedModifiers: ModifierSelection[];
          modifierAdditionalPrice: number;
        }) => {
          setNewOrderCustomization({
            ...newOrderCustomization,
            modifierSelections: updatedModifiers
          });
          setAdditionalPriceBreakdownState({
            ...additionalPriceBreakdownState,
            modifierSelectionPrice: modifierAdditionalPrice
          });
        }}
      />
    );
  };

  const renderTabContent = () => {
    switch (currentTab.id || 1) {
      case 2:
        return renderModifierSelection(
          detailedProductInfo?.modifierGroups || [],
          productDetailModalInfo ? productDetailModalInfo.isNew : false
        );
      case 3:
        return renderBundleSelection();
      case 4:
        if (detailedProductInfo && detailedProductInfo.item) {
          return renderModifierSelection(
            detailedProductInfo.item.modifierGroups || [],
            productDetailModalInfo ? productDetailModalInfo.isNew : false
          );
        }
        break;
      default:
        return <></>;
    }
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <S.TopMargin>
          <Loader width={150} color="black" />
        </S.TopMargin>
      );
    }

    return (
      <Tabs
        tabs={tabs}
        onChanged={({
          selectedTab
        }: {
          selectedTab: { id: number; label: string };
        }) => setCurrentTab(selectedTab)}
        selectedTab={currentTab}
      >
        {renderTabContent()}
      </Tabs>
    );
  };

  const handleAddProduct = () => {
    if (productDetailModalInfo?.isNew) {
      addBasketItem(
        productDetailModalInfo?.product,
        {
          ...newOrderCustomization,
          additionalPriceBreakdown: additionalPriceBreakdownState
        },
        quantity
      );
    } else {
      updateBasketItem(productDetailModalInfo?.product.id, quantity, {
        ...newOrderCustomization,
        additionalPriceBreakdown: additionalPriceBreakdownState
      });
    }
    handleModalClose();
  };

  const getProductTotalPrice = (): number =>
    ((detailedProductInfo?.salesPrice || 0) +
      (additionalPriceBreakdownState.modifierSelectionPrice || 0) +
      (additionalPriceBreakdownState.bundleSelectionPrice || 0)) *
    quantity;

  const canSave = (): boolean => {
    if (isLoading) {
      return false;
    }

    if (productDetailModalInfo?.product.type === 'Bundle') {
      if (
        newOrderCustomization?.bundleSelections &&
        detailedProductInfo?.bundleGroups &&
        newOrderCustomization?.bundleSelections.length ===
          detailedProductInfo?.bundleGroups.length
      ) {
        return true;
      }
      return false;
    }
    return true;
  };

  return (
    <ProductDetailModalComponent
      styles={styles}
      isHidden={!showProductDetailModal}
      priceLabel={t('Price')}
      quantityLabel={t('Quantity')}
      locale={defaultLocale}
      buttonLabel={
        productDetailModalInfo?.isNew
          ? t('Basket.AddToCart')
          : t('Basket.UpdateOrder')
      }
      totalPrice={getProductTotalPrice()}
      currencyCode={currencyCode}
      totalQuantity={quantity}
      description={detailedProductInfo?.description || ''}
      title={detailedProductInfo?.displayName || ''}
      imageUrl={detailedProductInfo?.imageUrl || ''}
      buttonDisabled={!canSave()}
      onButtonClicked={() => handleAddProduct()}
      onNumberPickerChanged={(actionType: '+' | '-') =>
        handleQuantityChange(actionType)
      }
      onCloseClicked={() => handleModalClose()}
    >
      {renderContent()}
    </ProductDetailModalComponent>
  );
};

export default ProductDetailModal;
