import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Price, Icon } from '@k3imagine/self-serve-components';

import GlobalContext from '../../../../../state/GlobalContext';
import { LayoutContext } from '../../../..';
import * as S from './BasketDiscountText.styles';

const BasketDiscountText = () => {
  const { t } = useTranslation();
  const { basket, currencyCode, defaultLocale } = useContext(GlobalContext);
  const { setShowDiscountModal, setDiscountModalInfo } = useContext(
    LayoutContext
  );
  const { discount } = basket;

  const renderDiscountType = () => {
    if (discount?.type === 'Percentage' || discount?.percent) {
      return <>{discount?.percent} %</>;
    }
    return (
      <Price
        value={discount?.amount || 0}
        currencyCode={currencyCode}
        locale={defaultLocale}
        color="#27ae60"
        fontSize={12}
        fontWeight={300}
      />
    );
  };

  return (
    <S.Container>
      <span>{t('Discount.Discount')}</span>
      <Icon
        type="Pencil"
        height="18px"
        width="18px"
        onClicked={() => {
          setShowDiscountModal(true);
          setDiscountModalInfo({ type: 'Basket' });
        }}
      />
      <S.DiscountAmount>{renderDiscountType()}</S.DiscountAmount>
      <S.OldPrice>
        <Price
          value={basket.totalPriceWithoutDiscount}
          currencyCode={currencyCode}
          locale={defaultLocale}
          fontWeight={300}
        />
      </S.OldPrice>
    </S.Container>
  );
};

export default BasketDiscountText;
