import styled from 'styled-components';

export const NegativeSalesContainer = styled.div`
  padding: 10px;
  margin-bottom: 5px;
  background-color: #ff4e61;
  color: white;
  text-align: center;
  height: 70px;
`;

export const Content = styled.div`
  margin: auto;
  height: 100%;
`;

export const NegativeSalesTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.1em;
  height: 40%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const NegativeSalesDescription = styled.div`
  font-size: 16px;
  letter-spacing: 0.1em;
  height: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
