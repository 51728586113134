import { useState, useEffect } from 'react';
import uniqid from 'uniqid';
import { navigate } from 'gatsby';
import { GridItemProps, RootComposerGroup, Breadcrumb } from '../../types';

const constructBreadcrumbsFromPath = (
  composerTiles: GridItemProps[],
  pathname: string,
  rootGroup?: RootComposerGroup
) => {
  const groupsFromPath: string[] = pathname.split('/').slice(2);
  if (groupsFromPath.length <= 0) {
    navigate('/');
  }

  let newBreadcrumbs: Breadcrumb[] = [];
  let foundGroup;

  groupsFromPath.forEach(groupUrlName => {
    switch (groupUrlName) {
      case 'all':
        newBreadcrumbs = [
          ...newBreadcrumbs,
          {
            id: uniqid(),
            label: 'All',
            value: { groupUrlName: 'all', referenceId: 'all' }
          }
        ];
        return;
      case rootGroup?.groupUrlName:
        newBreadcrumbs = [
          ...newBreadcrumbs,
          {
            id: uniqid(),
            label: rootGroup?.displayName || '',
            value: {
              groupUrlName: rootGroup?.groupUrlName,
              referenceId: rootGroup?.id
            }
          }
        ];
        return;
      default:
        foundGroup = composerTiles.find(
          g => g.value.groupUrlName === groupUrlName
        );
        // if we find a url group that does not match a groupUrlName, we will navigate back to home
        if (!foundGroup) {
          navigate(`/groups/${rootGroup?.groupUrlName}`);
          return [
            {
              id: uniqid(),
              label: rootGroup?.displayName || '',
              value: {
                groupUrlName: rootGroup?.groupUrlName,
                referenceId: rootGroup?.id
              }
            }
          ];
        }
        newBreadcrumbs = [
          ...newBreadcrumbs,
          {
            id: uniqid(),
            label: foundGroup?.label || '',
            value: {
              groupUrlName: foundGroup.value.groupUrlName,
              referenceId: foundGroup.referenceId
            }
          }
        ];
    }
  });

  return newBreadcrumbs;
};

export const useBreadcrumbs = (
  composerTiles: GridItemProps[],
  pathname: string,
  rootGroup?: RootComposerGroup
): [Breadcrumb[], Function] => {
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);

  useEffect(() => {
    setBreadcrumbs(
      constructBreadcrumbsFromPath(composerTiles, pathname, rootGroup)
    );
  }, [pathname]);

  return [breadcrumbs, setBreadcrumbs];
};
