import api from '../../utils/api';
import { SaleItemType, BasketItem, Discount, Clerk } from '../../types';
import { PaymentDetails } from '../payment/payment.type';
import { SalesResponse } from './sales.type';

const mapBasketItemsToSalesItems = (
  basketItem: BasketItem[]
): SaleItemType[] => {
  return basketItem.map(i => {
    let bundleSelections;
    let modifierSelections;
    if (i.customization?.modifierSelections) {
      modifierSelections = i.customization?.modifierSelections.map(m => ({
        modifierId: m.referenceId,
        quantity: m.quantity
      }));
    }
    if (i.customization?.bundleSelections) {
      bundleSelections = i.customization?.bundleSelections.map(m => ({
        referenceId: m.referenceId,
        bundleGroupId: m.bundleGroupId
      }));
    }

    return {
      referenceId: i.referenceId,
      type: i.type,
      quantity: i.quantity,
      orderDetail: i.customization?.orderDetail,
      modifierSelections,
      bundleSelections,
      discount: i.basketItemDiscounts
    };
  });
};

export const putSales = async (
  basketItems: BasketItem[],
  payments: PaymentDetails[],
  basketId: string,
  armId?: string,
  clerk?: Clerk,
  discount?: Discount
): Promise<SalesResponse> => {
  const saleItems = mapBasketItemsToSalesItems(basketItems);

  const { data } = await api.put('/Sales', {
    basketId,
    saleItems,
    payments,
    clerk,
    discount,
    armId
  });
  return data as SalesResponse;
};

export const postReceiptByEmail = async (
  email: string,
  basketId: string
): Promise<SalesResponse> => {
  const params = new URLSearchParams();
  params.append('email', email);
  params.append('basketId', basketId);

  const { data } = await api.post('/Sales/EmailReceipt', null, { params });
  return data as SalesResponse;
};
