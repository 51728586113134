import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@k3imagine/self-serve-components';

import GlobalContext from '../../../../../state/GlobalContext';
import { LayoutContext } from '../../../..';
import * as S from './DiscountButtons.styles';

const DiscountButtons = () => {
  const { t } = useTranslation();
  const { setShowDiscountModal, setDiscountModalInfo } = useContext(
    LayoutContext
  );
  const { updateBasketDiscount } = useContext(GlobalContext);

  return (
    <S.DiscountButtonsWrapper>
      <S.DiscountButton
        onClick={() => {
          setShowDiscountModal(true);
          setDiscountModalInfo({ type: 'Basket' });
        }}
      >
        {t('Discount.CustomDiscount')}
        <S.IconWrapper>
          <Icon type="Discount" width="19px" />
        </S.IconWrapper>
      </S.DiscountButton>
      <S.DiscountButton
        onClick={() =>
          updateBasketDiscount({ type: 'Percentage', percent: 10 })
        }
      >
        10%
      </S.DiscountButton>
      <S.DiscountButton
        onClick={() =>
          updateBasketDiscount({ type: 'Percentage', percent: 20 })
        }
      >
        20%
      </S.DiscountButton>
    </S.DiscountButtonsWrapper>
  );
};

export default DiscountButtons;
