import React, { useState, useEffect, useContext } from 'react';
import {
  Grid as GridComponent,
  notify
} from '@k3imagine/self-serve-components';
import { navigate } from 'gatsby';
import GlobalContext from '../../state/GlobalContext';
import { UpdateBasketItemInfo, GridItemProps } from '../../types';
import { LayoutContext } from '..';
import { useFilteredGridItems } from './useFilteredGridItems';
import { debounce } from 'lodash';

export const Grid = ({ location }: { location: Location }) => {
  const {
    setShowProductDetailModal,
    setProductDetailModalInfo,
    setShowBasketModal,
    breadcrumbs,
    search,
    setSearch
  } = useContext(LayoutContext);
  const {
    basket,
    currencyCode,
    composerTiles,
    getBasketItems,
    removeBasketItem,
    addBasketItem,
    updateBasketItem,
    rootComposerGroup,
    defaultLocale,
    visualProfileColors
  } = useContext(GlobalContext);
  const [currentGroupId, setCurrentGroupId] = useState<
    number | string | undefined
  >(rootComposerGroup?.id);

  const styles = {
    item: {
      group: {
        backgroundColor: visualProfileColors?.categoryTile?.background,
        labelTextColor: visualProfileColors?.categoryTile?.text
      }
    }
  };

  useEffect(() => {
    setCurrentGroupId(
      breadcrumbs.length > 0
        ? breadcrumbs[breadcrumbs.length - 1].value.referenceId
        : rootComposerGroup?.id
    );
    setSearch('');
  }, [breadcrumbs]);

  const [filteredItems] = useFilteredGridItems(
    search,
    currentGroupId,
    composerTiles,
    basket
  );

  const handleNumberPickerChange = ({
    actionType,
    item
  }: UpdateBasketItemInfo) => {
    if (actionType === '+') {
      if (item.value.isCustomizable) {
        setProductDetailModalInfo({ product: item, isNew: true });
        setShowProductDetailModal(true);
      } else {
        item.quantity = Number(item.quantity) + 1;
        debouncehHandler(item);
      }
    }
    if (actionType === '-') {
      const basketItems = getBasketItems(item.referenceId, item.type);
      if (basketItems.length > 1) {
        notify({
          message: 'Please choose which item you want to remove.',
          type: 'error'
        });
        setShowBasketModal(true);
      } else if (basketItems.length === 1) {
        if (item.quantity && item.quantity <= 1) {
          removeBasketItem(basketItems[0].id);
        } else {
          updateBasketItem(basketItems[0].id, basketItems[0].quantity - 1);
        }
      }
    }
  };
  const debouncehHandler = debounce((info: any) => {
    info.newQuantity = info.quantity;
    addBasketItem(info, {}, info.newQuantity);
  }, 1000);

  return (
    <GridComponent
      styles={styles}
      gridItems={filteredItems}
      currencyCode={currencyCode}
      locale={defaultLocale}
      onNumberPickerChanged={(info: UpdateBasketItemInfo) =>
        handleNumberPickerChange(info)
      }
      onItemClicked={(item: GridItemProps) => {
        if (item.type === 'Group' && item.referenceId) {
          navigate(`${location.pathname}/${item.value.groupUrlName}`);
        } else if (item.hasInfo) {
          setProductDetailModalInfo({ product: item, isNew: true });
          setShowProductDetailModal(true);
        }
      }}
    />
  );
};

export default Grid;
