import { createContext } from 'react';
import { GlobalContextType } from '../types';

const GlobalContext = createContext<GlobalContextType>({
  basket: {
    totalPrice: 0,
    totalQuantity: 0,
    basketItems: []
  },
  composerTiles: [],
  currencyCode: '',
  imageUrl: '',
  loading: false,
  error: false,
  idleResetMilliseconds: 30000,

  selectedPaymentDevice: undefined,
  setSelectedPaymentDevice: () => {},
  availablePaymentDevices: [],

  lastSale: undefined,
  setLastSale: () => {},

  currentCountry: 'en-gb',
  defaultLocale: 'en-gb',
  initialCountry: 'en-gb',
  negativeSaleMode: false,
  getBasketItems: () => {},
  addBasketItem: () => {},
  updateBasketItem: () => {},
  removeBasketItem: () => {},
  clearBasket: () => {},
  refresh: () => {},
  setNegativeSaleMode: () => {},
  setCurrentCountry: () => {},
  getNegativeSalesBasket: () => {},
  setClerk: () => {}
});

export default GlobalContext;
