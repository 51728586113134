import styled from 'styled-components';

export const Content = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: auto auto auto;
`;

export const DiscountTypeWrapper = styled.div`
  display: inline-block;
  margin: 5px auto;
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 300;
  text-align: center;
  margin: 10px;
`;

export const NumberBox = styled.div`
  width: 50%;
  margin: 10px auto;
  min-width: 200px;
  padding: 10px;
  background-color: white;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
`;

export const NumpadWrapper = styled.div`
  width: 60%;
  min-width: 200px;
  margin: 0 auto;
`;
