export const printTranslations = {
  giftCertificate: 'Gift certifcate',
  creditNote: 'Credit note',
  setStart: 'Set start',
  setLine: 'Set item',
  setEnd: 'Set end',
  discount: 'Discount',
  totalDiscount: 'Total discount',
  total: 'Total',
  itemAmount: 'Number of items',
  vatIncluded: 'VAT',
  vatIncludedTotal: 'Total VAT',
  price: 'Pris',
  youWereServedBy: 'Served by',
  currency: 'EUR',
  zero: 'Zero',
  one: 'One',
  two: 'Two',
  three: 'Three',
  four: 'Four',
  five: 'Five',
  six: 'Six',
  seven: 'Seven',
  eight: 'Eight',
  nine: 'Nine',
  return: 'Return',
  reason: 'Reason',
  returnReceipt: 'RECEIPT FOR RETURN OF ITEMS',
  customerName: 'Name',
  customerAddress: 'Address',
  customerCity: 'City',
  customerPhone: 'Phone',
  customerSignature: 'Signature',
  customerNumber: 'Customer',
  openDrawer: 'Opening till',
  vatNumber: 'VAT number',
  totalNumberItems: 'Number of items',
  expense: 'Expense',
  copy: 'Copy',
  terminalJournal: 'Terminal journal',
  receiptJournal: 'Receipt journal',
  periodStart: 'Period start',
  periodEnd: 'Period end',
  pickList: 'Pick list',
  orderLineVariant: 'Variant',
  orderLineNotes: 'Notes',
  paymentTypes: {
    Cash: 'Cash',
    Card: 'Card',
    ManualCard: 'Manual card',
    ForeignCurrency: 'Currency',
    GiftCertificate: 'Gift certifcate',
    ForeignGiftCertificate: 'Foreign gift certificate',
    CreditVoucher: 'Credit note',
    ForeignCreditVoucher: 'Foreign credit note',
    Webshop: 'Webshop',
    Account: 'Account',
    Bank: 'Bank',
    DebitCard: 'Debit card',
    CreditCard: 'Credit card',
    MobilePayment: 'Mobile payment',
    Change: 'Change',
    Rounding: 'Rounding',
    Currency: 'Currency',
    LoyaltyBonus: 'Loyalty bonus',
    LoyaltyDiscount: 'Loyalty Discount'
  }
};
