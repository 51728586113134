import { ArmDevice } from '../../../types';
import { PaymentDevice, PaymentProviderEnum } from '../payment.type';

export const mapArmDeviceToPaymentDevice = (armDevice: ArmDevice) => {
  const device: PaymentDevice = {
    type: PaymentProviderEnum.Arm,
    name: armDevice.name,
    uniqueId: armDevice.id.toString(),
    url: armDevice.url,
    meta: {
      id: armDevice.id,
      mac: armDevice.mac
    }
  };
  return device;
};
