import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Extensions } from '@k3imagine/imagine-extension-framework/dist/';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import {
  BasketModalContent,
  Modal,
  notify
} from '@k3imagine/self-serve-components';
import {
  getNewAccessToken,
  getPosAccessToken
} from '../../services/authentication.service';
import GlobalContext from '../../state/GlobalContext';
import { LayoutContext } from '../Layout/Layout';
import { WizardSteps } from './WizardSteps';

import {
  CompletePaymentProps,
  IPaymentService,
  PaymentDetails,
  PaymentType
} from '../../services/payment/payment.type';
import {
  BasketOverview,
  PaymentStatus as PaymentStatusComponent,
  Payment
} from './index';
import { BasketType, PaymentStatus } from '../../types';
import { SalesResponse } from '../../services/sales/sales.type';
import { putSales } from '../../services/sales/sales.service';
import { getPaymentService } from '../../services/payment/payment.service';

type ModalWizardProps = {
  showModal: boolean;
  handleShowModal: Function;
};

const ContentWrapper = styled.div`
  height: 100%;
`;

const BasketModalWizard = ({
  showModal,
  handleShowModal
}: ModalWizardProps) => {
  const [currentStep, setCurrentStep] = useState<WizardSteps>(
    WizardSteps.BasketOverview
  );
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>();
  const [paymentType, setPaymentType] = useState<PaymentType>();
  const [paymentStatusDescription, setPaymentStatusDescription] = useState<
    string
  >();

  const extensionIframeRef = React.useRef<HTMLIFrameElement>(null);

  const {
    clearBasket,
    setNegativeSaleMode,
    getNegativeSalesBasket,
    negativeSaleMode,
    rootComposerGroup,
    setLastSale,
    isTotalReached,
    basket,
    getPayments,
    clerk,
    shop,
    selectedPaymentDevice,
    currencyCode,
    shopId
  } = useContext(GlobalContext);

  const { t } = useTranslation();
  const { setScanningEnabled } = useContext(LayoutContext);

  const imagineExtensionFramework: Extensions = new Extensions(
    Number(shopId),
    basket.externalUid,
    `${process.env.API_URL}/api/${process.env.API_VERSION}`,
    getPosAccessToken() ?? undefined,
    async () => {
      const newToken = await getNewAccessToken();
      if (!newToken) {
        return '';
      }
      return newToken.access_token;
    }
  );

  let content;

  useEffect(() => {
    if (currentStep > WizardSteps.BasketOverview) {
      setScanningEnabled(false);
    } else {
      setScanningEnabled(true);
    }
  }, [currentStep]);

  const goToPrevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const goToNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const triggerExtensionEvent = async (event: string) => {
    const extensionSubscriptions = await imagineExtensionFramework.triggerEvent(
      event
    );
    if (extensionSubscriptions && extensionSubscriptions.length > 0) {
      const returnToStep = currentStep;
      setCurrentStep(WizardSteps.Extension);
      if (extensionIframeRef.current) {
        const extensionIframe: HTMLIFrameElement = extensionIframeRef.current;
        await imagineExtensionFramework.invokeExtensions(
          extensionSubscriptions,
          extensionIframe
        );
        ReactDOM.unmountComponentAtNode(extensionIframe);
      }
      setCurrentStep(returnToStep);
    }
  };

  const resetModalState = () => {
    setPaymentType(undefined);
    setPaymentStatus(undefined);
    setPaymentStatusDescription(undefined);
    setCurrentStep(WizardSteps.BasketOverview);
  };

  const closeModal = () => {
    resetModalState();
    handleShowModal(false);
  };

  const finishSale = async () => {
    let salesResponse: SalesResponse;
    if (!selectedPaymentDevice && paymentType !== 'ManualCard') {
      setPaymentStatus(PaymentStatus.Failure);
      setPaymentStatusDescription(t('Error.NoPaymentProviderSelected'));
      setCurrentStep(WizardSteps.PaymentStatus);
      return;
    }
    const currentPayments: PaymentDetails[] = getPayments();

    const finalBasket: BasketType = negativeSaleMode
      ? getNegativeSalesBasket()
      : basket;
    if (selectedPaymentDevice) {
      const PaymentService: IPaymentService = getPaymentService(
        selectedPaymentDevice
      );
      try {
        const isOnline = await PaymentService.wakeUp();
        if (!isOnline) {
          setPaymentStatus(PaymentStatus.Failure);
          setPaymentStatusDescription(t('Basket.ConnectorOffline'));
          goToNextStep();
          return;
        }
      } catch (e) {
        setPaymentStatus(PaymentStatus.Failure);
        setPaymentStatusDescription(t('Basket.ConnectorOffline'));
        goToNextStep();
        return;
      }
      try {
        salesResponse = await putSales(
          finalBasket.basketItems,
          currentPayments,
          finalBasket.externalUid,
          selectedPaymentDevice?.uniqueId.toString(),
          clerk,
          finalBasket.discount
        );
        try {
          const completePaymentProps: CompletePaymentProps = {
            salesResponse,
            shop
          };
          await PaymentService.completePayment(completePaymentProps);
        } catch (e) {
          if (paymentType !== 'ManualCard') {
            setPaymentStatus(PaymentStatus.Failure);
            setPaymentStatusDescription(t('Basket.FailedToSubmitPayment'));
            goToNextStep();
            return;
          }
          notify({
            message: t('Error.NoReceiptArm'),
            type: 'error'
          });
        }
        salesResponse.externalUid = basket.externalUid;
        setLastSale(salesResponse);
        clearBasket(true);
        setNegativeSaleMode(false);
        navigate(`/groups/${rootComposerGroup?.groupUrlName}`);
        setPaymentStatusDescription(t('Basket.PaymentApproved'));
        setPaymentStatus(PaymentStatus.Success);
        goToNextStep();
      } catch (e) {
        setPaymentStatus(PaymentStatus.Failure);
        setPaymentStatusDescription(t('Basket.FailedToCreateTransaction'));
        goToNextStep();
      }
    }
  };

  switch (currentStep) {
    case WizardSteps.BasketOverview:
      content = (
        <BasketOverview
          goNext={async (type: PaymentType) => {
            await triggerExtensionEvent('OnBeginCheckout');
            setPaymentType(type);
            goToNextStep();
          }}
          closeModal={closeModal}
        />
      );
      break;
    case WizardSteps.Payment:
      content = (
        <Payment
          paymentType={paymentType}
          goBack={() => goToPrevStep()}
          goNext={({
            status,
            paymentDescription
          }: {
            status: PaymentStatus;
            paymentDescription?: string;
          }) => {
            setPaymentStatus(status);
            if (status === PaymentStatus.Success) {
              if (isTotalReached(negativeSaleMode, currencyCode) === true) {
                finishSale();
              } else {
                setCurrentStep(WizardSteps.BasketOverview);
              }
            } else {
              setPaymentStatusDescription(paymentDescription);
              goToNextStep();
            }
          }}
        />
      );
      break;

    case WizardSteps.PaymentStatus:
      content = (
        <PaymentStatusComponent
          status={paymentStatus}
          description={paymentStatusDescription}
          onGoToBasketOverview={resetModalState}
          onGoToStart={() => {
            closeModal();
            navigate(`/groups/${rootComposerGroup?.groupUrlName}`);
          }}
        />
      );
      break;
    //  Extension Framework - START
    case WizardSteps.Extension: {
      const ExtensionIframe = styled.iframe`
        width: 100%;
        height: 100%;
        border: none;
      `;
      content = (
        <BasketModalContent>
          <ExtensionIframe ref={extensionIframeRef} />
        </BasketModalContent>
      );
      break;
    }
    //  Extension Framework - END

    default:
      return null;
  }

  return (
    <Modal isHidden={!showModal} onClose={closeModal}>
      <ContentWrapper>{content}</ContentWrapper>
    </Modal>
  );
};

export default BasketModalWizard;
