import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Loader,
  PriceSummaryList,
  BasketModalContent,
  Price,
  Numpad
} from '@k3imagine/self-serve-components';
import * as S from './CardPayment.styles';
import { getPaymentService } from '../../../../../../services/payment/payment.service';
import {
  IPaymentService,
  PaymentResponse,
  PaymentType,
  SubmitPaymentProps,
  PaymentDetails
} from '../../../../../../services/payment/payment.type';
import { PaymentStatus } from '../../../../payment.types';
import GlobalContext from '../../../../../../state/GlobalContext';
import { NegativeSalesWarning } from '../../../../..';
import { DisableScreenClick } from '../../Payment.styles';

type CardPaymentProps = {
  goNext: Function;
  goBack: Function;
};

const CardPayment = ({ goNext, goBack }: CardPaymentProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    selectedPaymentDevice,
    currencyCode,
    negativeSaleMode,
    visualProfileColors,
    defaultLocale,
    addPayment,
    getPayments,
    getRemainder
  } = useContext(GlobalContext);
  const { t } = useTranslation();
  const styles = {
    bottomButtons: {
      button: visualProfileColors?.button?.primary
    }
  };

  const [amount, setAmount] = useState<string>(
    getRemainder(negativeSaleMode).toString()
  );

  const initiatePaymentProcess = async () => {
    setIsLoading(true);

    if (!selectedPaymentDevice) {
      goNext({
        status: PaymentStatus.Failure,
        paymentDescription: t('Error.NoPaymentProviderSelected')
      });
      return;
    }

    const PaymentService: IPaymentService = getPaymentService(
      selectedPaymentDevice
    );

    const paymentType: PaymentType = 'Card';
    let paymentResponse: PaymentResponse;

    try {
      // making sure ISK don't have decimals
      const roundedTotalPrice =
        currencyCode === 'ISK'
          ? Number(Number(amount).toFixed(0))
          : Number(amount);
      const submitPaymentProps: SubmitPaymentProps = {
        paymentType,
        totalPrice: roundedTotalPrice,
        currency: currencyCode
      };

      paymentResponse = await PaymentService.submitPayment(submitPaymentProps);

      if (paymentResponse.isSuccessful) {
        const payment = { paymentType, amount: submitPaymentProps.totalPrice };
        addPayment(payment);

        goNext({
          status: PaymentStatus.Success,
          paymentDescription: ''
        });
      } else {
        goNext({
          status: PaymentStatus.Failure,
          paymentDescription: t('Basket.FailedToSubmitPayment')
        });
        return;
      }
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);

      goNext({
        status: PaymentStatus.Failure,
        paymentDescription: t('Basket.FailedToSubmitPayment')
      });
    }
  };

  const priceSummary = [
    {
      id: 1,
      label: t('Basket.Total'),
      value: getRemainder(negativeSaleMode),
      bold: true
    }
  ];

  const payments = getPayments();
  let priceSummaryIndex = 2;
  payments?.forEach((payment: PaymentDetails) => {
    priceSummary.push({
      id: priceSummaryIndex,
      label: t(`Basket.${payment.paymentType}`),
      value: payment.amount,
      bold: false
    });
    priceSummaryIndex += 1;
  });

  const renderContent = () => {
    if (isLoading) {
      return (
        <S.TopMargin>
          <DisableScreenClick />
          <Loader width={200} color="black" />
        </S.TopMargin>
      );
    }

    if (negativeSaleMode) {
      return (
        <>
          <PriceSummaryList
            listItems={priceSummary}
            currencyCode={currencyCode}
          />
          <NegativeSalesWarning />
          <S.SubTitle>{t('Basket.RefundCardInfo')}</S.SubTitle>
        </>
      );
    }

    return (
      <>
        <PriceSummaryList
          listItems={priceSummary}
          currencyCode={currencyCode}
        />
        <S.NumpadWrapper>
          <S.SubTitle>{t('Basket.Amount')}:</S.SubTitle>
          <S.NumberBox>
            <Price
              currencyCode={currencyCode}
              locale={defaultLocale}
              value={Number(amount)}
            />
          </S.NumberBox>
          <Numpad
            onValueChanged={({ value }: { value: string }) => {
              setAmount(value);
            }}
          />
          <S.SubTitle>{t('Basket.EnterAmountAndContinue')}</S.SubTitle>
        </S.NumpadWrapper>
      </>
    );
  };

  return (
    <BasketModalContent
      title={t('Basket.CardPayment')}
      styles={styles}
      buttonLabel={t('Basket.Confirm')}
      buttonIcon="BackArrow"
      buttonDisabled={
        Number(amount) > getRemainder() ||
        (Number(amount) <= 0 && !negativeSaleMode) ||
        isLoading
      }
      onIconButtonClicked={goBack}
      onButtonClicked={initiatePaymentProcess}
    >
      <S.Wrapper>{renderContent()}</S.Wrapper>
    </BasketModalContent>
  );
};

export default CardPayment;
