import * as CookieService from '../../utils/cookie.store';
import { CookieTypes } from '../../types';
import {
  getNamedLocalStorage,
  setNamedLocalStorage
} from '../../utils/namedLocalStorage';
import { IMigration } from '../migration.type';

const cookieToLocalStorage = (cookieName: CookieTypes) => {
  const valueFromCookie = CookieService.getCookie(cookieName);
  if (valueFromCookie) {
    setNamedLocalStorage(cookieName, valueFromCookie);
    CookieService.removeCookie(cookieName);
  }
};

const migratePosTokenToLocalStorage = (): IMigration => {
  const id: number = 2;
  const execute = () => {
    const posAccessTokenFromLocalStorage = getNamedLocalStorage(
      CookieTypes.PosAccessToken
    );

    if (posAccessTokenFromLocalStorage) {
      // there is a posAccessToken in local storage, nothing to migrate
      return;
    }

    cookieToLocalStorage(CookieTypes.PosAccessToken);
    cookieToLocalStorage(CookieTypes.PosRefreshToken);
  };

  return {
    id,
    execute
  };
};

export default migratePosTokenToLocalStorage;
