import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Numpad,
  BasketModalContent,
  Price,
  ButtonGroupSelector
} from '@k3imagine/self-serve-components';
import GlobalContext from '../../state/GlobalContext';
import { LayoutContext } from '..';
import { DiscountType } from '../../types/basket.type';
import { DiscountModalProps } from './DiscountModal.types';
import * as S from './DiscountModal.styles';

const DiscountModal = ({ type, basketItemId }: DiscountModalProps) => {
  const [discountAmount, setDiscountAmount] = useState<number>(0);
  const [discountType, setDiscountType] = useState<DiscountType>('Percentage');
  const { t } = useTranslation();
  const { showDiscountModal, setShowDiscountModal } = useContext(LayoutContext);
  const {
    currencyCode,
    defaultLocale,
    updateBasketDiscount,
    updateBasketItemDiscount
  } = useContext(GlobalContext);

  const discountTypes = [
    {
      id: 1,
      displayName: `${t('Discount.Percentage')} %`,
      value: 'Percentage'
    },
    { id: 2, displayName: `${t('Discount.Amount')}`, value: 'Amount' }
  ];

  const closeModal = () => {
    setDiscountAmount(0);
    setDiscountType('Percentage');
    setShowDiscountModal(false);
  };

  const applyDiscount = () => {
    const discount = {
      type: discountType,
      amount: discountType == 'Amount' ? discountAmount : 0,
      percent: discountType == 'Percentage' ? discountAmount : 0
    };
    switch (type) {
      case 'BasketItem':
        updateBasketItemDiscount(basketItemId, discount);
        break;
      case 'Basket':
      default:
        updateBasketDiscount(discount);
    }
    closeModal();
  };

  const renderDiscountType = () => {
    if (discountType === 'Percentage') {
      return <>{discountAmount} %</>;
    }
    return (
      <Price
        value={discountAmount || 0}
        currencyCode={currencyCode}
        locale={defaultLocale}
        fontSize={20}
        fontWeight={500}
      />
    );
  };

  return (
    <Modal
      isHidden={!showDiscountModal}
      onClose={() => setShowDiscountModal(false)}
    >
      <>
        {showDiscountModal && (
          <BasketModalContent
            title={t('Basket.Cart')}
            buttonLabel={t('Discount.ApplyDiscount')}
            onButtonClicked={() => applyDiscount()}
            leftButtonIcon="BackArrow"
            onIconButtonClicked={() => closeModal()}
          >
            <S.Content>
              <S.DiscountTypeWrapper>
                <ButtonGroupSelector
                  min={1}
                  max={1}
                  options={discountTypes}
                  selectedOptions={[discountTypes[0]]}
                  onChanged={({
                    selectedOptions
                  }: {
                    selectedOptions: { value: DiscountType }[];
                  }) => setDiscountType(selectedOptions[0].value)}
                />
              </S.DiscountTypeWrapper>
              <div>
                <S.Title>{t('Discount.EnterDiscount')}</S.Title>
                <S.NumberBox>{renderDiscountType()}</S.NumberBox>
              </div>
              <S.NumpadWrapper>
                <Numpad
                  onValueChanged={({ value }: { value: string }) =>
                    setDiscountAmount(Number(value))
                  }
                />
              </S.NumpadWrapper>
            </S.Content>
          </BasketModalContent>
        )}
      </>
    </Modal>
  );
};

export default DiscountModal;
