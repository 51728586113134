import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Loader } from '@k3imagine/self-serve-components';
import App from '../screens/App';

import useGlobalState from '../state/useGlobalState';
import useBasketState from '../state/useBasketState';
import GlobalContext from '../state/GlobalContext';
import useClerkState from '../state/useClerkState';
import i18n from '../locales/i18n';

import { runMigrations } from '../migrations';

runMigrations();

const LoaderWrapper = styled.div`
  margin-top: 25%;
`;

const Index = () => {
  const [isInitialized, setInitialized] = useState(false);

  const globalStateValues = {
    ...useGlobalState(),
    ...useBasketState(),
    ...useClerkState()
  };
  const { loading, currentCountry } = globalStateValues;

  useEffect(() => {
    async function initialize() {
      if (!loading) {
        await i18n.changeLanguage(currentCountry);
        setInitialized(true);
      }
    }
    initialize();
  }, [loading]);

  if (!isInitialized) {
    return (
      <>
        <LoaderWrapper>
          <Loader width={200} />
        </LoaderWrapper>
      </>
    );
  }

  return (
    <GlobalContext.Provider value={globalStateValues}>
      <App />
    </GlobalContext.Provider>
  );
};

export default Index;
