import styled from 'styled-components';

export const LoginContent = styled.div`
  text-align: center;
  color: black;
  height: 100%;

  overflow: auto;
  display: grid;
  grid-template-rows: auto auto;
`;

export const PasswordBox = styled.div`
  margin: auto;
  background: #fff;
  border-radius: 3px;
  width: 250px;
  height: 40px;
  line-height: 40px;

  font-size: 21px;
  letter-spacing: 0.1em;
`;

export const NumpadWrapper = styled.div`
  width: 70%;
  min-width: 250px;
  margin: 0 auto;
`;

export const FunctionsContent = styled.div`
  text-align: center;
  color: black;
  padding: 10px;
`;

export const SubTitle = styled.div`
  font-size: 18px;
  margin-top: 40px;
  margin-bottom: 20px;
`;

export const InfoText = styled.div`
  font-size: 16px;
  letter-spacing: 0.1em;
  font-weight: 300;
`;

export const FullScreenButtonWrapper = styled.div`
  display: inline-block;
  width: 100px;
  margin: 5px;
`;

export const PickList = styled.select`
  width: 300px;
  height: 50px;
  border: none;
  font-size: 16px;
`;

export const PickListOption = styled.option`
  height: 40px;
  font-size: 16px;
`;

export const ComponentWrapper = styled.div`
  width: 300px;
  margin: 30px auto;
`;

export const EmailReceiptWrapper = styled.div`
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 10px;
`;
