import { SalesReceipt } from '../../../sales/sales.type';

export type ArmPaymentRequest = {
  amount: string;
  paymentType: string;
  exchangerate: number; // 100
  currencyName: string;
  rounding: number; // 0
  receipt: object;
  group: string;
  contextInfo?: string;
  clerkNo?: number | 0;
  foreignCurrencyCode?: string | null;
  printTranslations?: object;
};

export type AddPaymentResponse = {
  sessionId: string;
};

export type TerminalStatusResponse = {
  cardPan: string;
  cardName: string;
  authorizationCode: string;
  terminalStatus: TerminalStatusResult;
};

export enum TerminalStatusResult {
  Information = -1,
  Success = 0,
  Failure = 2,
  Authorised = -3
}

export type ArmPrintReceipt = {
  receipt: SalesReceipt & {
    orderNumber: string;
    printReceipt: boolean;
  };
  printTranslations: object;
  cleanCash: string;
  isCopy?: boolean;
};
