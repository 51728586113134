import api from '../utils/api';
import { ComposerGroupItem, GridItemProps, Item, Bundle } from '../types';

const mapGridItems = (
  composerTiles: ComposerGroupItem[] = []
): GridItemProps[] =>
  composerTiles.map((item: ComposerGroupItem) => ({
    id: item.id,
    referenceId: item.referenceId,
    label: item.displayName,
    imageUrl: item.imageUrl,
    type: item.type,
    price: item.priceRange ? item.priceRange.from : 0,
    composerGroupId: item.composerGroupId,
    hasInfo: item.hasInfo,
    redirectUrl: item.redirectUrl,
    value: {
      groupUrlName: item.groupUrlName || '',
      isCustomizable: item.isCustomizable
    }
  }));

export const getSearchResults = async (
  filter: string
): Promise<GridItemProps[]> => {
  const params = new URLSearchParams();
  params.append('filter', filter);
  const { data } = await api.get('/Search/page/1/pageSize/50', { params });
  return mapGridItems(data.searchResults);
};

export const searchByBarcode = async (
  barcode: string
): Promise<{ isBundle: boolean; item: Item; bundle?: Bundle }> => {
  const { data } = await api.get(`/Search/barcode/${barcode}`);

  return data;
};
