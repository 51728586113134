import { useState, useEffect } from 'react';
import {
  GlobalStateType,
  GridItemProps,
  ComposerGroupItem,
  ArmDevice,
  Shop,
  VisualProfileColors,
  RootComposerGroup,
  Font
} from '../types';
import { getAllComposerTiles } from '../services/composer.service';
import { getShop } from '../services/shops.service';
import {
  getContextShopId,
  hasValidToken
} from '../services/authentication.service';
import { getConfig } from '../services/config.service';

import {
  PaymentDevice,
  PaymentProviderEnum
} from '../services/payment/payment.type';
import { useLocalStorage } from './useLocalStorage';
import { mapArmDeviceToPaymentDevice } from '../services/payment/payments/payment.utils';

const VIRTUAL_DEVICE: PaymentDevice = {
  type: PaymentProviderEnum.Virtual,
  name: 'Virtual',
  uniqueId: 'VIRTUAL',
  url: 'virtual_device'
};

const DEFAULT_COUNTRY = 'gb';
const DEFAULT_LOCALE = 'en-gb';

const mapGridItems = (composerTiles: ComposerGroupItem[] = []) =>
  composerTiles.map((item: ComposerGroupItem) => ({
    id: item.id,
    referenceId: item.referenceId,
    label: item.displayName,
    imageUrl: item.imageUrl,
    type: item.type,
    price: item.priceRange ? item.priceRange.from : 0,
    composerGroupId: item.composerGroupId,
    hasInfo: item.hasInfo,
    redirectUrl: item.redirectUrl,
    value: {
      groupUrlName: item.groupUrlName || '',
      isCustomizable: item.isCustomizable
    }
  }));

const getCountryFromLocale = (locale: string) =>
  locale.split('-')[1] || DEFAULT_COUNTRY;

const useGlobalState = (): GlobalStateType => {
  const [composerTiles, setComposerTiles] = useState<GridItemProps[]>([]);
  const [rootComposerGroup, setRootComposerGroup] = useState<
    RootComposerGroup
  >();
  const [isFetching, setFetching] = useState<boolean>(true);
  const [currencyCode, setCurrencyCode] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [initLoadCompleted, setInitLoadCompleted] = useState<boolean>(false);
  const [shop, setShop] = useState<Shop>();
  const [shopId, setShopId] = useState<string>();
  const [defaultLocale, setDefaultLocale] = useState<string>(DEFAULT_LOCALE);
  const [negativeSaleMode, setNegativeSaleMode] = useState<boolean>(false);

  // State used for Language Picker
  const [initialCountry, setInitialCountry] = useState<string>(DEFAULT_COUNTRY);
  const [currentCountry, setCurrentCountry] = useState<string>(DEFAULT_COUNTRY);
  const [supportedLanguages, setSupportedLanguages] = useState<string[]>([
    DEFAULT_LOCALE
  ]);

  const [selectedPaymentDevice, setSelectedPaymentDevice] = useLocalStorage(
    'selectedPaymentDevice'
  );

  const [lastSale, setLastSale] = useLocalStorage('lastSale');

  const [availablePaymentDevices, setAvailablePaymentDevices] = useState<
    PaymentDevice[]
  >([]);

  const [clerkNumberPrefix, setClerkNumberPrefix] = useState<string>();
  const [idleResetMilliseconds, setIdleResetMilliseconds] = useState<number>(
    30000
  );
  const [visualProfileColors, setVisualProfileColors] = useState<
    VisualProfileColors
  >();
  const [visualProfileFonts, setVisualProfileFonts] = useState<Font>();
  const [startScreenImage, setStartScreenImage] = useState<string>('');

  const refresh = () => {
    setLoading(true);
    setInitLoadCompleted(false);
    setCurrentCountry(initialCountry);
    setFetching(true);
  };

  useEffect(() => {
    setShopId(getContextShopId() || 'NO_SHOP_ID');
    const getComposerTiles = async () => {
      const isLoggedIn = await hasValidToken();
      if (isLoggedIn) {
        const responses = await Promise.all([
          getAllComposerTiles(),
          getShop(),
          getConfig()
        ]);

        if (responses.every((r: any) => r.status < 400 && r.data)) {
          const [composerResponse, shopResponse, configResponse] = responses;
          const { data: composerData } = composerResponse;
          const { data: shopData } = shopResponse;
          const { imageUrl: image, languages, currency } = shopData;
          const { data: configData } = configResponse;
          const defaultLanguage =
            languages?.find(lang => lang.defaultLanguage)?.languageCode ||
            DEFAULT_LOCALE;
          const supportedLangs = languages.map(lang => lang.languageCode);

          setSupportedLanguages(supportedLangs);
          setShop(shopData);
          const gridItems: GridItemProps[] = mapGridItems(composerData.tiles);
          setRootComposerGroup(composerData.rootComposerGroup);
          setComposerTiles(gridItems);
          setCurrencyCode(currency ? currency.currencyCode : '');
          setImageUrl(image || '');
          setDefaultLocale(defaultLanguage);
          setVisualProfileColors(configData?.profile?.color);
          setVisualProfileFonts(configData?.profile?.font);
          if (configData?.profile?.asset?.startScreenImages[0]?.url) {
            setStartScreenImage(
              configData.profile.asset.startScreenImages[0].url
            );
          }

          const country = getCountryFromLocale(defaultLanguage);
          setInitialCountry(country);
          setCurrentCountry(country);

          setClerkNumberPrefix(configData.clerkNumberPrefix);
          setIdleResetMilliseconds(configData.idleResetSeconds * 1000 || 30000);

          const armDevices: PaymentDevice[] = configData.armDevices.map(
            (armDevice: ArmDevice) => {
              return mapArmDeviceToPaymentDevice(armDevice);
            }
          );

          setAvailablePaymentDevices([VIRTUAL_DEVICE, ...armDevices]);

          setFetching(false);
        } else {
          setError(true);
        }
      }
    };
    if (initLoadCompleted) {
      setFetching(false);
      setLoading(false);
    } else {
      getComposerTiles();
      setInitLoadCompleted(true);
    }
  }, [isFetching]);

  useEffect(() => {
    const { userAgent } = navigator;

    if (userAgent === 'AndroidPortalApp_Castles_S1000') {
      const device: PaymentDevice = {
        type: PaymentProviderEnum.AndroidAppPayment,
        name: userAgent,
        uniqueId: userAgent
      };

      setSelectedPaymentDevice(device);
    }
  }, []);

  return {
    error,
    loading,
    imageUrl,
    currencyCode,
    composerTiles,
    rootComposerGroup,
    shopId,
    defaultLocale,
    initialCountry,
    currentCountry,
    setCurrentCountry,
    supportedLanguages,
    clerkNumberPrefix,
    idleResetMilliseconds,
    shop,
    selectedPaymentDevice,
    setSelectedPaymentDevice,
    lastSale,
    setLastSale,
    availablePaymentDevices,
    refresh,
    negativeSaleMode,
    setNegativeSaleMode,
    visualProfileColors,
    visualProfileFonts,
    startScreenImage
  };
};

export default useGlobalState;
