import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Numpad,
  PaymentButton,
  BasketModalContent
} from '@k3imagine/self-serve-components';
import { navigate } from 'gatsby';
import GlobalContext from '../../state/GlobalContext';
import * as S from './SupervisorModal.styles';
import { SupervisorModalType } from './SupervisorModal.types';
import PaymentProvider from './components/PaymentProvider';
import ClientReceipt from '../ClientReceipt/ClientReceipt';

const SupervisorModal = ({
  showModal,
  onModalClosed = () => {}
}: SupervisorModalType) => {
  const checkIfFullscreen = (): boolean => {
    return !!document.fullscreenElement;
  };

  const {
    clerkNumberPrefix,
    clearBasket,
    refresh,
    negativeSaleMode,
    setNegativeSaleMode,
    setClerk,
    clerk,
    visualProfileColors,
    lastSale
  } = useContext(GlobalContext);
  const [supervisorLoggedIn, setSupervisorLoggedIn] = useState<boolean>(false);
  const [enteredClerkPrefix, setEnteredClerkPrefix] = useState<string>();
  const [fullScreen, setFullScreen] = useState<boolean>(checkIfFullscreen());

  const { t } = useTranslation();

  const styles = {
    bottomButtons: {
      button: visualProfileColors?.button?.primary
    }
  };

  const prefixToPattern = () => {
    if (!enteredClerkPrefix) {
      return '';
    }
    const clerkPrefixArray = Array.from(enteredClerkPrefix, String);
    return clerkPrefixArray.map(() => 'x');
  };

  const matchedClerkPrefix = () => {
    if (enteredClerkPrefix) {
      return enteredClerkPrefix === clerkNumberPrefix;
    }
    return false;
  };

  const closeModal = () => {
    setSupervisorLoggedIn(false);
    setEnteredClerkPrefix(undefined);
    onModalClosed();
  };

  const requestFullScreen = () => {
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    }
    setFullScreen(true);
  };

  const exitFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
    setFullScreen(false);
  };

  const onClerkLogout = () => {
    clearBasket();
    setClerk();
    refresh();
    navigate('/');
  };

  const renderSupervisorLogin = () => {
    if (showModal) {
      return (
        <BasketModalContent
          title={t('Supervisor.EnterSupervisorCode')}
          onButtonClicked={() => setSupervisorLoggedIn(true)}
          buttonLabel={t('Continue')}
          buttonDisabled={!matchedClerkPrefix()}
          styles={styles}
        >
          <S.LoginContent>
            <S.PasswordBox>{prefixToPattern()}</S.PasswordBox>
            <S.NumpadWrapper>
              <Numpad
                onValueChanged={({ value }: { value: string }) =>
                  setEnteredClerkPrefix(value)
                }
              />
            </S.NumpadWrapper>
          </S.LoginContent>
        </BasketModalContent>
      );
    }
  };

  const renderSupervisorFunctions = () => {
    return (
      <BasketModalContent
        title={t('Supervisor.SupervisorFunctions')}
        onButtonClicked={closeModal}
        buttonLabel={t('Close')}
      >
        <S.FunctionsContent>
          <S.SubTitle>{t('Supervisor.PreviousReceipt')}:</S.SubTitle>
          {lastSale === undefined && (
            <S.InfoText>
              {t('Supervisor.PreviousReceiptNotAvailable')}
            </S.InfoText>
          )}
          {lastSale && (
            <S.ComponentWrapper>
              <ClientReceipt
                emailReceiptPlaceholder={t('Supervisor.SendReceiptByEmail')}
                receiptCopy
              />
            </S.ComponentWrapper>
          )}
          <S.SubTitle>{t('Supervisor.FullScreen')}:</S.SubTitle>
          <S.FullScreenButtonWrapper>
            <PaymentButton
              label="On"
              onClicked={() => requestFullScreen()}
              theme={fullScreen ? 'Dark' : 'Light'}
            />
          </S.FullScreenButtonWrapper>
          <S.FullScreenButtonWrapper>
            <PaymentButton
              label="Off"
              onClicked={() => exitFullScreen()}
              theme={fullScreen ? 'Light' : 'Dark'}
            />
          </S.FullScreenButtonWrapper>
          <S.SubTitle>{t('Supervisor.ChoosePaymentProvider')}:</S.SubTitle>
          <S.ComponentWrapper>
            <PaymentProvider />
          </S.ComponentWrapper>
          <S.SubTitle>{t('Supervisor.NegativeSaleMode')}:</S.SubTitle>
          <S.InfoText>{t('Supervisor.NegativeSaleModeWarning')}</S.InfoText>
          <S.FullScreenButtonWrapper>
            <PaymentButton
              label="On"
              onClicked={() => setNegativeSaleMode(true)}
              theme={negativeSaleMode ? 'Dark' : 'Light'}
            />
          </S.FullScreenButtonWrapper>
          <S.FullScreenButtonWrapper>
            <PaymentButton
              label="Off"
              onClicked={() => setNegativeSaleMode(false)}
              theme={negativeSaleMode ? 'Light' : 'Dark'}
            />
          </S.FullScreenButtonWrapper>
          <S.ComponentWrapper>
            <PaymentButton
              label={t('Supervisor.Logout')}
              onClicked={() => onClerkLogout()}
            />
          </S.ComponentWrapper>
        </S.FunctionsContent>
      </BasketModalContent>
    );
  };

  return (
    <Modal isHidden={!showModal} onClose={closeModal}>
      {supervisorLoggedIn || clerk?.isManager
        ? renderSupervisorFunctions()
        : renderSupervisorLogin()}
    </Modal>
  );
};

export default SupervisorModal;
