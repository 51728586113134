import {
  PaymentResponse,
  SubmitPaymentProps,
  CompletePaymentProps,
  IPaymentService
} from '../../payment.type';

const RESPONSE_DELAY = 3000;

export const VirtualPaymentService = (): IPaymentService => {
  const submitPayment = async (
    props: SubmitPaymentProps
  ): Promise<PaymentResponse> => {
    // eslint-disable-next-line
    console.log('Virtual - Submit Payment', props);

    return new Promise(resolve => {
      setTimeout(() => {
        const paymentResponse: PaymentResponse = {
          isSuccessful: true
        };

        resolve(paymentResponse);
      }, RESPONSE_DELAY);
    });
  };

  const completePayment = async (
    props: CompletePaymentProps
  ): Promise<void> => {
    // eslint-disable-next-line
    console.log('Virtual - Complete Payment', props);

    return new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, RESPONSE_DELAY);
    });
  };

  const printLastReceipt = async () => {
    // Intentionally left blank.
  };

  const wakeUp = async () => {
    return true;
  };

  return {
    submitPayment,
    completePayment,
    printLastReceipt,
    wakeUp
  };
};
