import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;

  // on a small device
  @media (max-width: 425px) {
    grid-template-rows: 15% 1fr;
  }

  @media (min-width: 425px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const Image = styled.div<{ imageUrl: string; cover: boolean }>`
  content: '';
  background: url(${({ imageUrl }) => imageUrl}) no-repeat center center;
  background-size: ${({ cover }) => (cover ? 'cover' : 'contain')};
`;

export const Content = styled.div`
  background: #f9f9f9;
  text-align: center;

  display: grid;
  grid-template-rows: auto auto 1fr auto;

  // on a small device
  @media (max-width: 425px) {
    grid-template-rows: auto 1fr auto;
  }
`;

export const Title = styled.div`
  animation: ${fadeIn} 1s;
  text-align: center;
  font-size: 25px;
  letter-spacing: 0.1em;
  color: black;
  padding: 10px;
  margin: auto;

  @media (max-width: 425px) {
    margin: 5% 0 5% 0;
  }
`;

export const NumpadWrapper = styled.div`
  padding: 15px;
  margin: auto;
  width: 70%;
  min-width: 200px;
  max-width: 400px;
  text-align: center;
`;

export const ButtonWrapper = styled.div`
  width: 80%;
  margin: auto;
`;

export const Input = styled.div`
  margin: 10px auto;
  background: #fff;
  border-radius: 3px;
  width: 70%;
  height: 40px;
  line-height: 40px;

  font-size: 21px;
  letter-spacing: 0.1em;
`;

export const LanguagePickerWrapper = styled.div`
  margin-left: auto;
  padding: 10px;

  // on a small device
  @media (max-width: 425px) {
    position: absolute;
    top: 10px;
    right: 10px;
  }
`;
