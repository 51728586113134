import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
`;

export const NegativeSaleStickyWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
`;

export const BottomWrapper = styled.div`
  > * {
    margin: 0 0;
  }
  background: #f9f9f9;
  padding-top: 5px;
  position: sticky;
  bottom: 0px;
`;

export const PaymentButtons = styled.div`
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: auto auto auto auto;

  // on small device
  @media (max-width: 500px) {
    grid-template-columns: 1fr 1fr 1fr auto;
  }
`;

export const PaymentButtonWrapper = styled.div`
  flex-grow: 1;
  margin: 0 5px;
`;
