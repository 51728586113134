import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import GlobalContext from '../../state/GlobalContext';
import * as S from './NegativeSalesWarning.styles';

const NegativeSalesWarning = () => {
  const { t } = useTranslation();
  const { negativeSaleMode } = useContext(GlobalContext);

  if (negativeSaleMode) {
    return (
      <S.NegativeSalesContainer>
        <S.Content>
          <S.NegativeSalesTitle>
            {t('NegativeSales.NegativeSalesMode')}
          </S.NegativeSalesTitle>
          <S.NegativeSalesDescription>
            {t('NegativeSales.NegativeSalesWarningText')}
          </S.NegativeSalesDescription>
        </S.Content>
      </S.NegativeSalesContainer>
    );
  }

  return null;
};

export default NegativeSalesWarning;
