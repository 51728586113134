import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, notify, PaymentButton } from '@k3imagine/self-serve-components';
import GlobalContext from '../../state/GlobalContext';
import { getPaymentService } from '../../services/payment/payment.service';
import { postReceiptByEmail } from '../../services/sales/sales.service';
import * as S from './ClientReceipt.styles';
import { ClientReceiptType } from './ClientReceipt.types';

const ClientReceipt = ({
  emailReceiptPlaceholder,
  receiptCopy
}: ClientReceiptType) => {
  const { t } = useTranslation();
  const [receiptEmail, setReceiptEmail] = useState<string>('');
  const { selectedPaymentDevice, lastSale, shop } = useContext(GlobalContext);

  const validEmail = (email: string) =>
    !email.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);

  const initializePrintReceipt = async () => {
    if (!selectedPaymentDevice) {
      notify({
        message: t('Error.NoArmSelectedWarning'),
        type: 'error'
      });
      return;
    }
    const printReceiptProps = {
      isCopy: receiptCopy || false,
      salesResponse: lastSale,
      shop
    };
    try {
      const PaymentService = getPaymentService(selectedPaymentDevice);
      await PaymentService.printLastReceipt(printReceiptProps);
    } catch (e) {
      notify({
        message: t('Error.NoReceiptArm'),
        type: 'error'
      });
    }
  };

  const sendReceiptInEmail = async () => {
    if (validEmail(receiptEmail)) {
      notify({
        message: t('Error.EmailNotOnCorrectFormat'),
        type: 'error'
      });
    } else {
      try {
        const basketId = lastSale?.externalUid;
        await postReceiptByEmail(
          receiptEmail || '',
          basketId?.toString() || ''
        );

        notify({
          message: t('Basket.ReceiptSentByEmail'),
          type: 'success'
        });
      } catch {
        notify({
          message: t('Basket.ThereWasAnErrorSendingReceipt'),
          type: 'error'
        });
      }
    }
  };

  return (
    <>
      <S.EmailReceiptWrapper>
        <Input
          value={receiptEmail}
          onChange={({ value }: { value: string }) => {
            setReceiptEmail(value);
          }}
          placeholder={emailReceiptPlaceholder}
        />
        <PaymentButton
          label={t('Send')}
          theme="Dark"
          onClicked={sendReceiptInEmail}
        />
      </S.EmailReceiptWrapper>
      <PaymentButton
        label={t('Basket.PrintReceipt')}
        onClicked={initializePrintReceipt}
      />
    </>
  );
};

export default ClientReceipt;
