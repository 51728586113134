import { useEffect, useState } from 'react';
import {
  createBasket,
  getBasketById,
  patchRemoveAllItemsByBasketId,
  patchUpdateItemsByBasketId,
  patchRemoveItemsById,
  patchAddItemsByBasketId
} from '../services/basket.service';
import { PaymentDetails } from '../services/payment/payment.type';
import {
  BasketType,
  BasketItem,
  GridItemProps,
  GridItemType,
  OrderCustomization,
  Discount
} from '../types/basket.type';
import { BasketStateType } from '../types/global-state-data.type';
import {
  getNamedLocalStorage,
  setNamedLocalStorage
} from '../utils/namedLocalStorage';

const emptyBasket: BasketType = {
  externalUid: '',
  totalPrice: 0,
  totalPriceWithoutDiscount: 0,
  totalQuantity: 0,
  basketPayments: [],
  basketItems: [],
  basketCurrencyCode: ''
};

// adjusts for rounding errors on summing up amount
const roundPrice = (price: number) =>
  Math.round((price + Number.EPSILON) * 100) / 100;

const currencyDoesntHaveDecimal = (code: string): boolean => {
  const currenciesThatsDoNotHaveDecimalcode = ['ISK', 'SEK', 'NOK'];
  return currenciesThatsDoNotHaveDecimalcode.includes(code.toUpperCase());
};

const calculatePriceWithDiscount = (
  price: number,
  currencyCode: string = '',
  discount?: Discount
): number => {
  if (
    discount &&
    (discount.type === 'Percentage' || discount.percent > 0) &&
    price > 0
  ) {
    let priceWithDiscount = price * ((100 - discount.percent) * 0.01);
    priceWithDiscount = currencyDoesntHaveDecimal(currencyCode)
      ? Number(Number(priceWithDiscount).toFixed(0))
      : Number(priceWithDiscount);
    return priceWithDiscount > 0 ? priceWithDiscount : 0;
  }

  if (
    discount &&
    (discount.type === 'Amount' || discount.amount > 0) &&
    price > 0
  ) {
    const priceWithDiscount = price - discount.amount;
    return priceWithDiscount > 0 ? priceWithDiscount : 0;
  }

  return price;
};

const calculateBasketTotalPrice = (
  basketItems: BasketItem[],
  currency: string,
  basketDiscount?: Discount
): {
  basketTotalPrice: number;
  basketTotalPriceWithoutDiscount: number;
  totalQuantity: number;
} => {
  let basketTotalPrice = 0;
  let basketTotalPriceWithoutDiscount = 0;
  let totalQuantity = 0;

  basketItems.forEach(item => {
    basketTotalPriceWithoutDiscount += (item.price || 0) * item.quantity;
    totalQuantity += item.quantity;
  });

  basketTotalPrice = calculatePriceWithDiscount(
    basketTotalPriceWithoutDiscount,
    currency,
    basketDiscount
  );

  return {
    basketTotalPrice: roundPrice(basketTotalPrice),
    basketTotalPriceWithoutDiscount: roundPrice(
      basketTotalPriceWithoutDiscount
    ),
    totalQuantity
  };
};

const calculateBasketItemTotalPrice = (
  price: number = 0,
  currency: string,
  customization?: OrderCustomization,
  discount?: Discount
): { totalPrice: number; totalPriceWithoutDiscount: number } => {
  const totalPriceWithoutDiscount = price;
  const totalPrice = calculatePriceWithDiscount(
    totalPriceWithoutDiscount,
    currency,
    discount
  );
  return {
    totalPrice: roundPrice(totalPrice),
    totalPriceWithoutDiscount: roundPrice(totalPriceWithoutDiscount)
  };
};

const useBasketState = (): BasketStateType => {
  const [basketLoading, setBasketLoading] = useState<boolean>(true);
  const [basket, setBasket] = useState<BasketType>(emptyBasket);
  const [basketId, setBasketId] = useState<string>('');

  const toggleBasketLoading = (newState: boolean) => {
    setBasketLoading(newState);
  };

  const getInitialBasket = async (): Promise<void> => {
    const basketJson = getNamedLocalStorage('basket');
    const bask = basketJson ? JSON.parse(basketJson) : {};
    let result;
    if (bask.externalUid) {
      const { data } = await getBasketById(bask.externalUid);
      if (data == null)
        // This should not happen but it will if you switch say from Dev to Prod and use the same UI instance
        result = await createBasket();
      else {
        result = data;
        if (result.completedTime != null)
         result = await createBasket();
      }
    } else {
      result = await createBasket();
    }
    setBasketId(result.externalUid as string);
    setNamedLocalStorage('basket', JSON.stringify(result));
    setBasket(result);
    setBasketLoading(false);
  };

  const getBasketItems = (
    referenceId?: number,
    type?: GridItemType
  ): BasketItem[] => {
    return basket.basketItems.filter(
      (basketItem: BasketItem) =>
        basketItem.referenceId === referenceId && basketItem.type === type
    );
  };

  const updateBasket = async (updatedBasketItems: BasketType) => {
    const {
      basketTotalPrice,
      basketTotalPriceWithoutDiscount,
      totalQuantity
    } = calculateBasketTotalPrice(
      updatedBasketItems.basketItems,
      basket.basketCurrencyCode,
      basket.discount
    );

    const updatedBasket = {
      ...basket,
      externalUid: basket.externalUid,
      basketItems: updatedBasketItems.basketItems,
      totalPrice: basketTotalPrice,
      totalPriceWithoutDiscount: basketTotalPriceWithoutDiscount,
      totalQuantity
    };

    setBasket(updatedBasket);
    setNamedLocalStorage('basket', JSON.stringify(updatedBasket));
  };

  const updateBasketDiscount = (basketDiscount?: Discount) => {
    const {
      basketTotalPrice,
      basketTotalPriceWithoutDiscount
    } = calculateBasketTotalPrice(
      basket.basketItems,
      basket.basketCurrencyCode,
      basketDiscount
    );

    const updatedBasket = {
      ...basket,
      totalPrice: basketTotalPrice,
      totalPriceWithoutDiscount: basketTotalPriceWithoutDiscount,
      discount: basketDiscount
    };

    setBasket(updatedBasket);
    setNamedLocalStorage('basket', JSON.stringify(updatedBasket));
  };

  const updateBasketItem = async (
    id: number,
    quantity: number,
    customization: OrderCustomization = {}
  ) => {
    const basketItemToUpdate = basket.basketItems.find(
      item => item.id === id
    ) as BasketItem;
    const newBasketItem = {
      ...basketItemToUpdate,
      bundleSelections: customization.bundleSelections,
      modifierSelections: customization.modifierSelections,
      quantity
    };

    const {
      totalPrice,
      totalPriceWithoutDiscount
    } = calculateBasketItemTotalPrice(
      newBasketItem.price,
      basket.basketCurrencyCode,
      customization,
      newBasketItem.basketItemDiscounts
    );

    newBasketItem.totalPrice = totalPrice;
    newBasketItem.totalPriceWithoutDiscount = totalPriceWithoutDiscount;

    toggleBasketLoading(true);
    const newBasket = await patchUpdateItemsByBasketId(basketId, newBasketItem);
    updateBasket(newBasket);
    toggleBasketLoading(false);
  };

  const addBasketItem = async (
    newItem: GridItemProps,
    customization: OrderCustomization = {},
    newQuantity: number
  ) => {
    const identicalItemFound = newItem.value.isCustomizable
      ? basket.basketItems.find(
          i =>
            i.referenceId === newItem.referenceId &&
            i.type === newItem.type &&
            JSON.stringify(i.customization) === JSON.stringify(customization)
        )
      : basket.basketItems.find(
          i => i.referenceId === newItem.referenceId && i.type === newItem.type
        );
    if (identicalItemFound && identicalItemFound.id) {
      updateBasketItem(
        identicalItemFound.id,
        (identicalItemFound.quantity = newQuantity),
        customization
      );
    } else {
      toggleBasketLoading(true);
      const newBasket = await patchAddItemsByBasketId(basketId, {
        id: newItem.id,
        referenceId: newItem.referenceId || 0,
        type: newItem.type,
        label: newItem.label,
        quantity: newQuantity || 1,
        ...customization
      });
      setBasket(newBasket);
      setNamedLocalStorage('basket', JSON.stringify(newBasket));
      toggleBasketLoading(false);
    }
  };

  const removeBasketItem = async (id: number) => {
    toggleBasketLoading(true);
    const newBasket = await patchRemoveItemsById(basketId, id);
    updateBasket(newBasket);
    toggleBasketLoading(false);
  };

  const updateBasketItemDiscount = async (id: number, discount: Discount) => {
    const updatedItem = basket.basketItems.find(i => {
      if (i.id === id) {
        const {
          totalPrice,
          totalPriceWithoutDiscount
        } = calculateBasketItemTotalPrice(
          i.price,
          basket.basketCurrencyCode,
          i.customization,
          discount
        );
        return {
          ...i,
          totalPrice,
          totalPriceWithoutDiscount,
          discount
        };
      }
      return i;
    }) as BasketItem;

    const newBasket = await patchUpdateItemsByBasketId(basketId, updatedItem);
    updateBasket(newBasket);
  };

  const clearBasket = async (clearBasketId: boolean = false) => {
    emptyBasket.basketPayments = [];
    let newBasket;
    if (clearBasketId) {
      await setNamedLocalStorage('basket', JSON.stringify(emptyBasket));
      getInitialBasket();
    } else {
      toggleBasketLoading(true);
      newBasket = await patchRemoveAllItemsByBasketId(basketId);
      setBasket(newBasket);
      toggleBasketLoading(false);
    }
  };

  const getNegativeSalesBasket = (): BasketType => {
    return {
      externalUid: basket.externalUid,
      totalQuantity: -basket.totalQuantity,
      totalPrice: -basket.totalPrice,
      basketCurrencyCode: basket.basketCurrencyCode,
      totalPriceWithoutDiscount: -basket.totalPriceWithoutDiscount,
      basketItems: basket.basketItems.map((i: BasketItem) => ({
        ...i,
        quantity: -i.quantity
      }))
    };
  };

  const addPayment = (payment: PaymentDetails) => {
    let payments = [];
    if (basket.basketPayments) {
      payments = [...basket.basketPayments, payment];
      basket.basketPayments.push(payment);
    } else {
      payments.push(payment);
    }

    const updatedBasket = { ...basket, payments };
    setBasket(updatedBasket);
    setNamedLocalStorage('basket', JSON.stringify(updatedBasket));
  };

  const getPayments = () => {
    return basket.basketPayments;
  };

  const getRemainder = (negativeSaleMode: boolean = false) => {
    let remainder = 0;
    if (!basket.basketPayments || !basket.basketPayments.length) {
      // Negative sale always returns one full payment
      remainder = negativeSaleMode ? -basket.totalPrice : basket.totalPrice;
    } else {
      const sumOfPayments = basket.basketPayments.reduce(
        (sum, { amount }) => sum + amount,
        0
      );

      remainder = basket.totalPrice - sumOfPayments;
    }

    // adjusting for rounding errors
    return roundPrice(remainder);
  };

  const isTotalReached = (
    negativeSaleMode: boolean = false,
    currencyCode: string
  ) => {
    if (!basket.basketPayments || !basket.basketPayments.length) {
      return false;
    }
    let sumOfPayments = basket.basketPayments.reduce(
      (sum, { amount }) => sum + amount,
      0
    );
    if (negativeSaleMode) {
      sumOfPayments = -sumOfPayments;
    }

    sumOfPayments = roundPrice(sumOfPayments);
    const total =
      currencyCode === 'ISK'
        ? basket.totalPrice.toFixed(0)
        : roundPrice(basket.totalPrice);

    return sumOfPayments >= total;
  };

  useEffect(() => {
    getInitialBasket();
  }, []);

  return {
    basket,
    basketLoading,
    addBasketItem,
    updateBasketItem,
    getBasketItems,
    removeBasketItem,
    clearBasket,
    getNegativeSalesBasket,
    addPayment,
    getPayments,
    getRemainder,
    isTotalReached,
    updateBasketDiscount,
    updateBasketItemDiscount,
    toggleBasketLoading
  };
};

export default useBasketState;
