import styled from 'styled-components';

export const Container = styled.div`
  letter-spacing: 0.05em;
  font-size: 16px;
  font-weight: 300;

  margin: 10px 15px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  & > :last-child {
    text-align: right;
  }
`;

export const DiscountAmount = styled.span`
  color: #27ae60;
`;

export const OldPrice = styled.span`
  padding-left: 15px;
  text-decoration: line-through;
  font-weight: 200;
  color: #c4c4c4;
`;
