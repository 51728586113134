import React from 'react';
import { PaymentType } from '../../../../services/payment/payment.type';
import { CardPayment, CashPayment, ManualCardPayment } from './paymentTypes';

type PaymentProps = {
  paymentType?: PaymentType;
  goNext: Function;
  goBack: Function;
};

const Payment = ({ paymentType, goNext, goBack }: PaymentProps) => {
  const renderPayment = () => {
    switch (paymentType) {
      case 'Card':
        return <CardPayment goNext={goNext} goBack={goBack} />;
      case 'Cash':
        return <CashPayment goNext={goNext} goBack={goBack} />;
      case 'ManualCard':
        return <ManualCardPayment goNext={goNext} goBack={goBack} />;
      default:
        return <></>;
    }
  };

  return <>{renderPayment()}</>;
};

export default Payment;
