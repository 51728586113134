import axios from 'axios';
import * as CookieService from '../utils/cookie.store';
import { CookieTypes, Token } from '../types/index';
import {
  getNamedLocalStorage,
  setNamedLocalStorage,
  removeNamedLocalStorage
} from '../utils/namedLocalStorage';

const isTokenEmpty = (token: string | undefined | null): boolean => {
  if (
    !token ||
    token === undefined ||
    token === null ||
    token === 'undefined' ||
    token === 'null'
  ) {
    return true;
  }
  return false;
};

export const getPosAccessToken = (): string | null => {
  return getNamedLocalStorage(CookieTypes.PosAccessToken);
};

export const setPosAccessToken = (newToken: string): void => {
  setNamedLocalStorage(CookieTypes.PosAccessToken, newToken);
};

export const getPosRefreshToken = (): string | null => {
  return getNamedLocalStorage(CookieTypes.PosRefreshToken);
};

export const setPosRefreshToken = (newToken: string): void => {
  setNamedLocalStorage(CookieTypes.PosRefreshToken, newToken);
};

export const getPortalRefreshToken = (): string | undefined => {
  return CookieService.getCookie(CookieTypes.PortalRefreshToken);
};

export const getContextShopId = (): string | undefined => {
  const shopContextCookie = CookieService.getCookie(CookieTypes.ContextShopId);
  if (process.env.NODE_ENV === 'development') {
    CookieService.setCookie(
      CookieTypes.PortalRefreshToken,
      'mock_first_refresh_token'
    );
    if (isTokenEmpty(shopContextCookie)) {
      return '1';
    }
  }

  return shopContextCookie;
};

export const logout = (): void => {
  CookieService.removeCookie(CookieTypes.PortalApiToken);
  CookieService.removeCookie(CookieTypes.PortalRefreshToken);
  removeNamedLocalStorage(CookieTypes.PosAccessToken);
  removeNamedLocalStorage(CookieTypes.PosRefreshToken);
  window.location.href = process.env.PORTAL_URL || '';
};

// We check if we have PosRefreshToken if we do we use it to get new access token/
// If not we check if we have PortalRefreshToke, if we do we use it, if not we log user out.
export const getNewAccessToken = async (): Promise<Token | undefined> => {
  let refreshToken: string | null | undefined = getPosRefreshToken();
  if (isTokenEmpty(refreshToken)) {
    refreshToken = getPortalRefreshToken();
  }
  if (isTokenEmpty(refreshToken)) {
    logout();
    return;
  }

  let response;
  const formData = new FormData();
  formData.append('refresh_token', refreshToken || '');
  formData.append('grant_type', 'refresh_token');

  try {
    response = await axios.post(`${process.env.API_URL}/token`, formData, {
      headers: {
        'Shop-Context': getContextShopId()
      }
    });
  } catch (error) {
    logout();
    return;
  }

  // if refresh token is invalid. The server will return with status 200, invalid_grant
  if (response?.status === 200 && response?.data.error === 'invalid_grant') {
    logout();
  } else {
    setPosAccessToken(response?.data.access_token);
    setPosRefreshToken(response?.data.refresh_token);
    CookieService.setCookie(CookieTypes.TenantId, response?.data.tenantId);
  }

  return response?.data;
};

export const isLoggedIn = async (): Promise<boolean> => {
  const posAccessToken = getPosAccessToken();
  if (isTokenEmpty(posAccessToken)) {
    const newToken = await getNewAccessToken();

    if (newToken && isTokenEmpty(newToken.access_token)) {
      return false;
    }
  }

  return true;
};

export const hasValidToken = async (): Promise<boolean> => {
  const newToken = await getNewAccessToken();

  if (newToken && isTokenEmpty(newToken.access_token)) {
    return false;
  }

  return true;
};
