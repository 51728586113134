import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Router, RouteComponentProps } from '@reach/router';
import { Helmet } from 'react-helmet';
import { Toaster } from '@k3imagine/self-serve-components';

import * as S from './App.styles';
import * as AuthService from '../services/authentication.service';
import GlobalContext from '../state/GlobalContext';
import { StartScreen, Grid, Layout, ErrorScreen } from '../components';

type RouteProps = {
  component: React.ComponentType<any>;
  children?: React.ReactNode;
} & RouteComponentProps;

const TokenProtectedRoute = ({
  component: Component,
  path,
  children,
  ...rest
}: RouteProps) => {
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
  useEffect(() => {
    const checkForAuthentication = async () => {
      setIsAuthorized(await AuthService.isLoggedIn());
    };
    checkForAuthentication();
  }, []);

  return (
    <>{isAuthorized ? <Component {...rest}>{children}</Component> : null}</>
  );
};

const ClerkProtectedRoute = ({
  clerk,
  ...rest
}: RouteProps & { clerk?: Clerk }) => {
  if (!clerk) {
    return <TokenProtectedRoute path="/" component={StartScreen} />;
  }

  return <TokenProtectedRoute {...rest} />;
};

const App = () => {
  const { shopId, error, clerk, visualProfileFonts } = useContext(
    GlobalContext
  );
  const { t } = useTranslation();

  const renderContent = () => {
    if (shopId === 'NO_SHOP_ID') {
      return (
        <ErrorScreen
          title={t('Error.Oooops')}
          message={t('Error.NoShopIdProvided')}
        />
      );
    }

    if (error) {
      return (
        <ErrorScreen
          title={t('Error.Oooops')}
          message={t('Error.UnexpectedError')}
        />
      );
    }

    return (
      <Router>
        <TokenProtectedRoute path="/*" component={StartScreen} />
        <ClerkProtectedRoute path="/groups/*" component={Layout} clerk={clerk}>
          <ClerkProtectedRoute path="/" component={Grid} clerk={clerk} />
        </ClerkProtectedRoute>
      </Router>
    );
  };

  return (
    <>
      <Helmet>
        <title>Simple Pos</title>
        <meta
          name="viewport"
          content="width=device-width, height=device-height, initial-scale=1, user-scalable=no"
        />
      </Helmet>
      <S.GlobalStyles font={visualProfileFonts} />
      {renderContent()}
      <Toaster />
    </>
  );
};

export default App;
