import migrateArmDeviceToPaymentDevice from './1-arm-device-to-payment-device';
import posTokenToLocalStorage from './2-pos-token-to-local-storage';

const jobs = [
  migrateArmDeviceToPaymentDevice(),
  posTokenToLocalStorage()
  // Next job comes here.
];

export default jobs;
