import { AsyncAutoResetEvent } from '@esfx/async-autoresetevent';
import { ImagineAndroidAppInterface } from '../types/appInterface.types';

const AppInterface: ImagineAndroidAppInterface =
  typeof window !== 'undefined' && (window as any).ImagineAndroidAppInterface;

if (typeof window !== 'undefined' && AppInterface) {
  AppInterface.AppActivity = new AsyncAutoResetEvent();
  AppInterface.PaymentComplete = function(
    transactionStatusResponse: any
  ): void {
    this.PaymentResponse = transactionStatusResponse;
    this.AppActivity.set();
  };
}
export default AppInterface;
