import { AxiosResponse } from 'axios';
import { Shop } from '../../types';
import { SalesResponse } from '../sales/sales.type';

export enum PaymentProviderEnum {
  Arm = 'Arm',
  AndroidAppPayment = 'AndroidAppPayment',
  Virtual = 'Virtual'
}

export type PaymentDevice = {
  type: PaymentProviderEnum;
  name: string;
  uniqueId: string;
  url?: string; // Not all Payment Services use a URL.
  meta?: object; // Any extra information relevant for the service.
};

export type PaymentType = 'Card' | 'ManualCard' | 'Cash' | 'Change';

export type PaymentDetails = {
  paymentType: PaymentType;
  amount: number;
};

export type PaymentResponse = {
  isSuccessful: boolean;
  cardName?: string;
};

export type PaymentMeta = {
  address?: string;
};

export type PaymentServiceError = {
  message: string;
};

type PaymentInformation = {
  cardName?: string;
};

export type SubmitPaymentProps = {
  paymentType: string;
  totalPrice: number;
  currency: string;
};

export type CompletePaymentProps = {
  salesResponse: SalesResponse;
  paymentInformation?: PaymentInformation;
  shop?: Shop;
};

export type PrintReceiptProps = {
  isCopy: boolean;
  salesResponse?: SalesResponse;
  shop?: Shop;
};

export interface IPaymentService {
  submitPayment: (props: SubmitPaymentProps) => Promise<PaymentResponse>;
  completePayment: (props: CompletePaymentProps) => Promise<void>;
  printLastReceipt: (props: PrintReceiptProps) => Promise<void>;
  wakeUp: () => Promise<any>;
}
