import { v4 as uuidv4 } from 'uuid';

import {
  PaymentResponse,
  SubmitPaymentProps,
  IPaymentService,
  PrintReceiptProps
} from '../../payment.type';
import AppInterface from './app-interface/AppInterface';

import {
  ImagineAndroidAppInterface,
  AppPaymentResponse
} from './types/appInterface.types';

export type AndroidServiceType = {
  submitPayment: () => Promise<any>;
};

export const AndroidPaymentService = (): IPaymentService => {
  const App: ImagineAndroidAppInterface = AppInterface;

  const submitPayment = async (
    props: SubmitPaymentProps
  ): Promise<PaymentResponse> => {
    const { totalPrice, currency } = props;

    const transactionId: string = uuidv4();
    const normalizedAmount = totalPrice * 100;
    const vatAmount = 0; // TODO: All VAT info is missing from SimplePOS. This will have to be added when the Basket-MS gets implemented.

    let paymentResponse: PaymentResponse;

    if (props.paymentType === 'Card') {
      App.RequestPayment(transactionId, normalizedAmount, vatAmount, currency);
      await App.AppActivity.wait();
      if (App.PaymentResponse === undefined) {
        console.error('Payment response is undefined!');
        paymentResponse = {
          isSuccessful: false,
          cardName: ''
        };
      } else {
        const appResponse: AppPaymentResponse = App.PaymentResponse;
        paymentResponse = {
          isSuccessful: appResponse.success,
          cardName: appResponse.cardName
        };
      }
    } else {
      paymentResponse = {
        isSuccessful: true
      };
    }

    return paymentResponse;
  };

  const completePayment = async () => {};
  const wakeUp = async () => {
    return true;
  };

  const printLastReceipt = async (props: PrintReceiptProps) => {
    const printReceipt = {
      ...props.salesResponse?.receipt,
      orderNumber: `${props.salesResponse?.orderId}`,
      payments: props.salesResponse?.receipt.payments
    };
    const receiptSerialized = JSON.stringify(printReceipt);
    const shopSettingsSerialized = JSON.stringify(props.shop);

    App.PrintReceipt(receiptSerialized, shopSettingsSerialized, false);
  };

  return {
    submitPayment,
    completePayment,
    printLastReceipt,
    wakeUp
  };
};
