import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 75px 1fr 1fr 1fr;
`;

export const PaymentButton = styled.button<{
  backgroundColor: string;
  textColor: string;
  disabled?: boolean;
}>`
  height: 90px;
  background: ${({ backgroundColor, disabled }) =>
    disabled ? '#8E8E8E' : backgroundColor};
  color: ${({ textColor }) => textColor};

  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  font-size: 17px;
  letter-spacing: 0.1em;
  text-align: center;
  border-radius: 10px;
  border: none;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  margin: 20px 5px 20px 5px;
`;

export const TrashButton = styled.button<{
  backgroundColor: string;
  textColor: string;
}>`
  height: 70px;
  width: 70px;
  background: black;
  color: white;

  cursor: pointer;
  font-size: 16px;
  text-align: center;
  border-radius: 100px;
  border: none;
  margin: auto;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  margin: auto 0 auto 5px;
`;

export const TrashIconWrapper = styled.div`
  width: 20px;
  height: 20px;
  margin: auto;
`;

export const IconWrapper = styled.div`
  width: 40px;
  height: 30px;
  margin: auto;
`;
