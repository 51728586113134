import React, { useState, useEffect, useContext, createContext } from 'react';
import styled, { css } from 'styled-components';
import { navigate } from 'gatsby';
import {
  Layout as LayoutComponent,
  Loader,
  notify
} from '@k3imagine/self-serve-components';
import { useTranslation } from 'react-i18next';
import GlobalContext from '../../state/GlobalContext';
import { BasketModalWizard } from '../BasketModalWizard';
import { useDebounce } from '../../state/useDebounce';
import { useBreadcrumbs } from './useBreadcrumbs';
import { DiscountModalInfoType } from '../DiscountModal/DiscountModal.types';

import {
  GridItemProps,
  OrderCustomization,
  Breadcrumb,
  BasketItem
} from '../../types';
import {
  ProductDetailModal,
  SupervisorModal,
  NegativeSalesWarning,
  DiscountModal,
  BarcodeReader
} from '..';

export const LayoutContext = createContext<{
  search: string;
  setSearch: Function;
  breadcrumbs: Breadcrumb[];
  setBreadcrumbs: Function;
  showProductDetailModal: boolean;
  productDetailModalInfo?: {
    product: GridItemProps & { customization?: OrderCustomization };
    isNew: boolean;
  };
  setShowProductDetailModal: Function;
  setProductDetailModalInfo: Function;
  showBasketModal: boolean;
  setShowBasketModal: Function;
  showDiscountModal: boolean;
  setShowDiscountModal: Function;
  setDiscountModalInfo: Function;
  scanningEnabled: boolean;
  setScanningEnabled: Function;
}>({
  search: '',
  setSearch: () => {},
  breadcrumbs: [],
  setBreadcrumbs: () => {},
  showProductDetailModal: false,
  setShowProductDetailModal: () => {},
  setProductDetailModalInfo: () => {},
  showBasketModal: false,
  setShowBasketModal: () => {},
  showDiscountModal: false,
  setShowDiscountModal: () => {},
  setDiscountModalInfo: () => {},
  scanningEnabled: true,
  setScanningEnabled: () => {}
});

const TopMargin = styled.div`
  margin-top: 20%;
`;

const NegativeSaleFixedWrapper = styled.div`
  position: fixed;
  width: 100%;
  z-index: 1;
`;

const Content = styled.div<{ negativeSale: boolean }>`
  ${({ negativeSale }) =>
    negativeSale &&
    css`
      padding-top: 100px;
    `}
`;

export default ({
  children,
  location
}: {
  children: any;
  location: Location;
}) => {
  const [showBasketModal, setShowBasketModal] = useState<boolean>(false);
  const [scanningEnabled, setScanningEnabled] = useState<boolean>(false);
  const [showProductDetailModal, setShowProductDetailModal] = useState<boolean>(
    false
  );
  const [showSupervisorModal, setShowSupervisorModal] = useState<boolean>(
    false
  );
  const [showDiscountModal, setShowDiscountModal] = useState<boolean>(false);
  const [productDetailModalInfo, setProductDetailModalInfo] = useState<{
    product: BasketItem;
    isNew: boolean;
  }>();
  const [discountModalInfo, setDiscountModalInfo] = useState<{
    type: DiscountModalInfoType;
    basketItemId?: number;
  }>();

  const [search, setSearch] = useState('');

  const {
    basket,
    imageUrl,
    loading,
    clerk,
    refresh,
    clearBasket,
    setClerk,
    selectedPaymentDevice,
    negativeSaleMode,
    visualProfileColors,
    rootComposerGroup,
    composerTiles
  } = useContext(GlobalContext);
  const { t } = useTranslation();

  const [breadcrumbs, setBreadcrumbs] = useBreadcrumbs(
    composerTiles,
    location.pathname,
    rootComposerGroup
  );

  const styles = {
    basketButton: {
      background: visualProfileColors?.button?.primary?.background
    }
  };

  const goToRootGroup = () => {
    navigate(`/groups/${rootComposerGroup?.groupUrlName}`);
    setSearch('');
  };

  useEffect(() => {
    if (!selectedPaymentDevice) {
      notify({
        message: t('Error.NoArmSelectedWarning'),
        type: 'error'
      });
    }
  }, []);

  useEffect(() => {
    // When the modal is shown, we want a fixed body so that the application does not scroll in the background
    document.body.style.overflow =
      showBasketModal || showProductDetailModal || showSupervisorModal
        ? 'hidden'
        : 'initial';
  }, [showBasketModal, showProductDetailModal, showSupervisorModal]);

  const breadcrumbsClicked = (breadcrumb: Breadcrumb) => {
    const breadcrumbIndex = breadcrumbs.indexOf(breadcrumb);
    const newBreadcrumbs = breadcrumbs.slice(0, breadcrumbIndex + 1);
    const groupPath = newBreadcrumbs.map(b => b.value.groupUrlName).join('/');
    setBreadcrumbs(newBreadcrumbs);
    navigate(`/groups/${groupPath}`);
  };

  const goBackOneBreadcrumb = () => {
    if (breadcrumbs.length > 1) {
      const newBreadcrumbs = breadcrumbs.slice(0, breadcrumbs.length - 1);
      const groupPath = newBreadcrumbs.map(b => b.value.groupUrlName).join('/');
      setBreadcrumbs(newBreadcrumbs);
      navigate(`/groups/${groupPath}`);
    } else {
      goToRootGroup();
    }
  };

  const onClerkLogout = () => {
    clearBasket();
    setClerk();
    refresh();
  };

  const renderContent = () => {
    if (loading) {
      return (
        <TopMargin>
          <Loader width={200} />
        </TopMargin>
      );
    }
    return <>{children}</>;
  };

  return (
    <>
      <LayoutContext.Provider
        value={{
          breadcrumbs,
          setBreadcrumbs,
          showProductDetailModal,
          setShowProductDetailModal,
          productDetailModalInfo,
          setProductDetailModalInfo,
          showBasketModal,
          setShowBasketModal,
          showDiscountModal,
          setShowDiscountModal,
          setDiscountModalInfo,
          search: useDebounce(300, search),
          setSearch,
          scanningEnabled,
          setScanningEnabled
        }}
      >
        <LayoutComponent
          styles={styles}
          searchPlaceholder={t('Search')}
          logoUrl={imageUrl}
          breadcrumbs={breadcrumbs}
          orderReference={`${clerk?.firstName || ''} ${clerk?.lastName || ''}`}
          onBreadcrumbClicked={breadcrumbsClicked}
          onLogoClicked={goToRootGroup}
          onSettingsClicked={() => setShowSupervisorModal(true)}
          onLogoutClicked={() => onClerkLogout()}
          onBasketClicked={() => {
            setShowBasketModal(true);
          }}
          basketQuantity={basket.totalQuantity}
          onBackButtonClicked={
            breadcrumbs.length > 1 ? goBackOneBreadcrumb : undefined
          }
          searchValue={search}
          onSearchClicked={async (query: string) => setSearch(query)}
        >
          <>
            <NegativeSaleFixedWrapper>
              <NegativeSalesWarning />
            </NegativeSaleFixedWrapper>
            {scanningEnabled && <BarcodeReader />}
            <Content negativeSale={negativeSaleMode}>{renderContent()}</Content>
          </>
        </LayoutComponent>
        <BasketModalWizard
          showModal={showBasketModal}
          handleShowModal={(showModalValue: boolean) =>
            setShowBasketModal(showModalValue)
          }
        />
        <SupervisorModal
          showModal={showSupervisorModal}
          onModalClosed={() => setShowSupervisorModal(false)}
        />
        <ProductDetailModal />
        <DiscountModal
          type={discountModalInfo?.type}
          basketItemId={discountModalInfo?.basketItemId}
        />
      </LayoutContext.Provider>
    </>
  );
};
