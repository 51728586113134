import { useEffect, useState } from 'react';
import { GridItemProps, BasketItem, BasketType } from '../../types';
import { getSearchResults } from '../../services/search.service';

const filterByGroupId = (
  groupId: string | number,
  allComposerTiles: GridItemProps[]
): GridItemProps[] => {
  return allComposerTiles.filter(
    item => item.composerGroupId === Number(groupId)
  );
};

const filterAllGroups = (
  allComposerTiles: GridItemProps[]
): GridItemProps[] => {
  let mappedGridItems: GridItemProps[] = [];
  allComposerTiles.forEach(item => {
    if (
      item.type === 'Group' &&
      !mappedGridItems.find(i => i.referenceId === item.referenceId)
    ) {
      mappedGridItems = [...mappedGridItems, item];
    }
  });
  return mappedGridItems;
};

const getTotalQuantity = (
  product: GridItemProps,
  basketItems: BasketItem[]
) => {
  let totalQuantity = 0;
  if (basketItems && basketItems.length > 0)
    basketItems.forEach(basketItem => {
      if (
        basketItem.referenceId === product.referenceId &&
        basketItem.type === product.type
      ) {
        totalQuantity += basketItem.quantity;
      }
    });

  return totalQuantity;
};

const updateQuantity = (
  gridItems: GridItemProps[],
  basketItems: BasketItem[]
) => {
  return gridItems.map(item => ({
    ...item,
    quantity: getTotalQuantity(item, basketItems)
  }));
};

export const useFilteredGridItems = (
  search: string,
  groupId: string | number | undefined,
  allComposerTiles: GridItemProps[],
  basket: BasketType
): [GridItemProps[], Function] => {
  const [filteredItems, setFilteredItems] = useState<GridItemProps[]>([]);

  // Here we filter what grid items to show in grid depending on conditions.
  useEffect(() => {
    let filtered: GridItemProps[] = [];

    const filterItems = async () => {
      if (search?.length > 0) {
        filtered = await getSearchResults(search);
      } else if (groupId === 'all') {
        filtered = filterAllGroups(allComposerTiles);
      } else if (groupId !== undefined) {
        filtered = filterByGroupId(groupId, allComposerTiles);
      }
      setFilteredItems(updateQuantity(filtered, basket.basketItems));
    };

    filterItems();
  }, [allComposerTiles, groupId, search]);

  // Here we update the quantity on the already filtered items
  useEffect(() => {
    if (filteredItems.length > 0) {
      setFilteredItems(updateQuantity(filteredItems, basket.basketItems));
    }
  }, [basket]);

  return [filteredItems, setFilteredItems];
};
