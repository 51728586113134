import api from '../utils/api';
import { Item } from '../types/item.type';

export const getItemById = async (itemId: number): Promise<Item> => {
  try {
    const { data } = await api.get(`/item/${itemId}`);
    return data;
  } catch (e) {
    return e.response;
  }
};

export const getItemByBarcode = async (barcode: string): Promise<Item> => {
  try {
    const { data } = await api.get(`/item/barcode/${barcode}`);
    return data;
  } catch (e) {
    return e.response;
  }
};
