import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  TransactionStatus,
  BasketModalContent
} from '@k3imagine/self-serve-components';
import GlobalContext from '../../../../state/GlobalContext';
import { PaymentStatus as PaymentStatusType } from '../../../../types/index';
import ClientReceipt from '../../../ClientReceipt/ClientReceipt';

type PaymentStatusProps = {
  status?: PaymentStatusType;
  onGoToBasketOverview: Function;
  onGoToStart: Function;
  description?: string;
};

const Wrapper = styled.div`
  text-align: center;
  color: black;
  font-size: 1rem;
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
`;

const ReceiptButtonWrapper = styled.div`
  margin: 60px 40px 20px 40px;
`;

const PaymentStatus = ({
  status,
  onGoToBasketOverview,
  onGoToStart,
  description
}: PaymentStatusProps) => {
  const { visualProfileColors, lastSale } = useContext(GlobalContext);
  const { t } = useTranslation();

  const styles = {
    bottomButtons: {
      button: visualProfileColors?.button?.primary
    }
  };

  const renderContent = () => {
    if (status === PaymentStatusType.Success) {
      return (
        <>
          <TransactionStatus
            status="Success"
            title={t('Basket.Success')}
            description={description || t('Basket.PaymentApproved')}
            averageWaitTimeSeconds={lastSale?.averageWaitTimeSeconds}
            orderNumber={lastSale?.orderId}
            orderNumberLabel={`${t('Order')}:`}
            averageWaitingTimeLabel={`${t('EstimatedWaitingTime')}:`}
            buttonText={t('Basket.StartNewTransaction')}
            onButtonClicked={() => onGoToStart()}
          />
          <ReceiptButtonWrapper>
            <ClientReceipt
              emailReceiptPlaceholder={t('Basket.SendReceiptByEmail')}
            />
          </ReceiptButtonWrapper>
        </>
      );
    }

    return (
      <TransactionStatus
        status="Failed"
        title={t('Basket.TransactionFailed')}
        description={description || ''}
        buttonText={t('Basket.BackToOverview')}
        onButtonClicked={() => onGoToBasketOverview()}
      />
    );
  };

  return (
    <BasketModalContent title={t('Basket.TransactionStatus')} styles={styles}>
      <Wrapper>{renderContent()}</Wrapper>
    </BasketModalContent>
  );
};

export default PaymentStatus;
