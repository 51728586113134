import React, { useContext } from 'react';
import {
  BasketList,
  BasketModalContent,
  PriceSummaryList
} from '@k3imagine/self-serve-components';
import { useTranslation } from 'react-i18next';
import GlobalContext from '../../../../state/GlobalContext';
import { LayoutContext } from '../../../Layout/Layout';
import {
  GridItemProps,
  BasketItem,
  NumberPickerActionType
} from '../../../../types/basket.type';
import { NegativeSalesWarning } from '../../..';
import { PaymentDetails } from '../../../../services/payment/payment.type';
import { DiscountButtons } from './DiscountButtons';
import { BasketDiscountText } from './BasketDiscountText';
import { BottomButtons } from './BottomButtons';
import * as S from './BasketOverview.styles';
import { debounce } from 'lodash';
import { ProductInfo } from '@k3imagine/self-serve-components/dist/components/Basic/ProductListItem/ProductListItem.types';

type BasketOverviewProps = {
  goNext: Function;
  closeModal: Function;
};

const BasketOverview = ({ goNext, closeModal }: BasketOverviewProps) => {
  const {
    basket,
    currencyCode,
    removeBasketItem,
    defaultLocale,
    composerTiles,
    updateBasketItem,
    clearBasket,
    visualProfileColors,
    negativeSaleMode,
    getPayments,
    getRemainder
  } = useContext(GlobalContext);
  const {
    setShowProductDetailModal,
    setProductDetailModalInfo,
    setShowDiscountModal,
    setDiscountModalInfo
  } = useContext(LayoutContext);
  const styles = {
    bottomButtons: {
      button: visualProfileColors?.button?.primary
    }
  };
  const { t } = useTranslation();

  const handleNumberPickerChange = ({
    actionType,
    item
  }: {
    actionType: NumberPickerActionType;
    item: BasketItem;
  }) => {
    if (actionType === '+') {
      item.quantity = item.quantity + 1;
      debouncehHandler(item);
    }
    if (actionType === '-') {
      if (item.quantity && item.quantity <= 1) {
        removeBasketItem(item.id);
      } else {
        item.quantity = item.quantity - 1;
        debouncehHandler(item);
      }
    }
  };

  const debouncehHandler = debounce((info: any) => {
    updateBasketItem(info.id, info.quantity, info.customization);
  }, 500);

  const mapBasketItems = (basketItems: BasketItem[]) => {
    if (basketItems && basketItems.length > 0) {
      return basketItems.map(item => {
        const tilesInfo = composerTiles.find(
          tile =>
            tile.referenceId === item.referenceId && tile.type === item.type
        );

        const newItem = { ...tilesInfo, ...item };
        return newItem;
      });
    }
    return [];
  };

  const emptyBasket = () => {
    clearBasket();
    closeModal();
  };

  const priceSummary = [
    {
      id: 1,
      label: t('Basket.Total'),
      value: getRemainder(negativeSaleMode),
      bold: true
    }
  ];

  const payments = getPayments();
  let priceSummaryIndex = 2;

  payments?.forEach((payment: PaymentDetails) => {
    priceSummary.push({
      id: priceSummaryIndex,
      label: t(`Basket.${payment.paymentType}`),
      value: payment.amount,
      bold: false
    });

    priceSummaryIndex += 1;
  });

  return (
    <BasketModalContent styles={styles} title={t('Basket.Cart')}>
      <>
        <S.NegativeSaleStickyWrapper>
          <NegativeSalesWarning />
        </S.NegativeSaleStickyWrapper>
        <S.Wrapper>
          <DiscountButtons />
          <BasketList
            emptyListText={t('Basket.NoItems')}
            basketItems={mapBasketItems(basket.basketItems)}
            onNumberPickerChanged={(info: {
              actionType: NumberPickerActionType;
              item: BasketItem;
            }) => handleNumberPickerChange(info)}
            onRemoveClicked={({ item }: { item: GridItemProps }) => {
              removeBasketItem(item.id);
            }}
            onEditClicked={({ item }: { item: GridItemProps }) => {
              setProductDetailModalInfo({ product: item, isNew: false });
              setShowProductDetailModal(true);
            }}
            onDiscountClicked={({ item }: { item: GridItemProps }) => {
              setShowDiscountModal(true);
              setDiscountModalInfo({
                type: 'BasketItem',
                basketItemId: item.id
              });
            }}
            onRowClicked={({ item }: { item: GridItemProps }) => {
              setProductDetailModalInfo({ product: item, isNew: false });
              setShowProductDetailModal(true);
            }}
            currencyCode={currencyCode}
            locale={defaultLocale}
          />
          <S.BottomWrapper>
            <div>
              {basket?.discount &&
                (basket?.discount?.amount > 0 ||
                  basket?.discount?.percent > 0) && <BasketDiscountText />}
            </div>
            <PriceSummaryList
              listItems={priceSummary}
              currencyCode={currencyCode}
            />
            <BottomButtons goNext={goNext} emptyBasket={emptyBasket} />
          </S.BottomWrapper>
        </S.Wrapper>
      </>
    </BasketModalContent>
  );
};

export default BasketOverview;
