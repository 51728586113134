import { PaymentDevice } from '../../services/payment/payment.type';
import { mapArmDeviceToPaymentDevice } from '../../services/payment/payments/payment.utils';
import { ArmDevice } from '../../types';
import {
  getNamedLocalStorage,
  removeNamedLocalStorage,
  setNamedLocalStorage
} from '../../utils/namedLocalStorage';
import { IMigration } from '../migration.type';

type CachedArmDevice = ArmDevice & {
  type: string;
};

const SELECTED_PAYMENT_DEVICE = 'selectedPaymentDevice';
const ARM_DEVICE = 'armDevice';

const migrateArmDeviceToPaymentDevice = (): IMigration => {
  const id: number = 1;
  const execute = () => {
    const cachedPaymentDevice = getNamedLocalStorage(SELECTED_PAYMENT_DEVICE);
    if (cachedPaymentDevice !== null) {
      // User has set Payment device, nothing to migrate.
      return;
    }

    const cachedArmDevice = getNamedLocalStorage(ARM_DEVICE);
    if (cachedArmDevice === null) {
      // User had no ARM device previously set, nothing to migrate.
      return;
    }

    const cachedArmDeviceObj: CachedArmDevice = JSON.parse(cachedArmDevice);
    if (
      cachedArmDeviceObj.type === 'Virtual' ||
      cachedArmDeviceObj.type === 'VIRTUAL'
    ) {
      // We don't migrate Virtual Arm Device selections for safety reasons.
      return;
    }

    const paymentDevice: PaymentDevice = mapArmDeviceToPaymentDevice(
      cachedArmDeviceObj
    );
    const paymentDeviceSerialized = JSON.stringify(paymentDevice);

    // Cache the ARM device as Payment Device.
    setNamedLocalStorage(SELECTED_PAYMENT_DEVICE, paymentDeviceSerialized);
    //
    // Remove ARM device from cache
    removeNamedLocalStorage(ARM_DEVICE);
  };

  return {
    id,
    execute
  };
};

export default migrateArmDeviceToPaymentDevice;
