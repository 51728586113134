import styled from 'styled-components';

export const Wrapper = styled.div`
  font-size: 1rem;
  padding: 4% 40px 0 40px;
`;

export const SubTitle = styled.div`
  margin-top: 20px;
  text-align: center;
  margin-bottom: 20px;
  letter-spacing: 0.05em;
  font-weight: 300;
`;

export const NumpadWrapper = styled.div`
  width: 80%;
  margin: auto;
`;

export const NumberBox = styled.div`
  text-align: center;
  margin: auto;
  width: 80%;
  height: 40px;
  line-height: 40px;
  letter-spacing: 0.1em;
  font-size: 21px;

  border: none;
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
`;

export const TopMargin = styled.div`
  margin-top: 50%;
`;
