import styled from 'styled-components';

export const DisableScreenClick = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  background-color: #b2b2b2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 110;
`;
